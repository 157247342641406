// import { capital } from 'case';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import Users from '@/views/Users.vue';
import NewUser from '@/views/NewUser.vue';
import LoanApplicationSingle from '@/views/LoanApplicationSingle.vue';
import LoanApplication from '@/views/LoanApplication.vue';
import LoanApplicationForm from '@/views/LoanApplicationForm.vue';
import LoanOCRApplicationForm from '@/views/LoanOCRApplicationForm.vue';
import UploadDocuments from '@/views/UploadDocuments.vue';
import RejectedApplication from '@/views/RejectedApplication.vue';
import ExistingCustomer from '@/views/ExistingCustomer.vue';
import ThankYou from '@/views/ThankYou.vue';
import OCRSubmitted from '@/views/OCRSubmitted.vue';
import Account from '@/views/Account.vue';
import Disclosure from '@/views/Disclosure.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import store from '@/store';
import { checkForLatestTokens, storeTokens } from '@/auth/utils/tokens';

export default [
  // generic app routes
  {
    path:      '/',
    name:      'dashboard',
    component: Dashboard,
    props(route) {
      return {
        query: route.query.search,
      };
    },
  },
  {
    path:      '/login',
    name:      'Login',
    component: Login,
  },
  {
    path:     '/oauth2redirect',
    name:     'oauth2redirect',
    redirect: (to) => {
      storeTokens(to.query.access_token, to.query.refresh_token);
      return '/';
    },
  },

  // user routes
  {
    path:      '/users',
    name:      'Users',
    component: Users,
    beforeEnter(to, from, next) {
      store.dispatch('user/fetchAll');
      next();
    },
  },
  {
    path:      '/users/new',
    name:      'NewUser',
    component: NewUser,
  },

  // loan-application
  {
    path:      '/loan-applications/:id',
    name:      'LoanApplicationSingle',
    component: LoanApplicationSingle,
    props:     true,
  },
  {
    path:      '/loan-applications/filter/:status',
    name:      'LoanApplication',
    component: LoanApplication,
    props:     true,
  },
  // loan application form
  {
    path:      '/new-loan-application',
    name:      'LoanApplicationForm',
    component: LoanApplicationForm,
    props:     true,
  },
  // loan application form with Ocrolus partial fill
  {
    path:      '/new-loan-application/:prospect_id',
    name:      'LoanApplicationFormIncomplete',
    component: LoanApplicationForm,
    props(route) {
      return {
        prospect_id: route.params.prospect_id,
      };
    },
  },
  // loan ocr application form
  {
    path:        '/new-loan-ocr-application',
    name:        'LoanOCRApplicationForm',
    component:   LoanOCRApplicationForm,
    beforeEnter: (to, from, next) => {
      store.dispatch('portalUser/getPermissions').then(() => {
        if (!store.state.portalUser.permissions.appOcr) {
          next('/');
        }
        next();
      }).catch(() => {
        next('/');
      });
    },
    props: true,
  },
  // upload-documents page
  {
    path:        '/accounts/:id/upload-documents',
    name:        'UploadDocuments',
    component:   UploadDocuments,
    beforeEnter: (to, from, next) => {
      checkForLatestTokens().then(() => {
        next();
      }).catch(() => {
        next('/');
      });
    },
    props(route) {
      return {
        id:              route.params.id,
        loanOfferStatus: route.params.loanOfferStatus,
      };
    },
  },
  // loan-application reject page
  {
    path:      '/reject',
    name:      'RejectedApplication',
    component: RejectedApplication,
    props:     true,
  },
  // existing customer
  {
    path:      '/existing-customer',
    name:      'ExistingCustomer',
    component: ExistingCustomer,
    props:     true,
  },
  // application completed
  {
    path:      '/thank-you',
    name:      'ThankYou',
    component: ThankYou,
    props:     true,
  },
  // loan application ocr submitted
  {
    path:      '/ocr-submitted',
    name:      'OCRSubmitted',
    component: OCRSubmitted,
    props:     true,
  },
  // account
  {
    path:      '/accounts/:id',
    name:      'Account',
    component: Account,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },
  // disclosure
  {
    path:      '/disclosures/:id',
    name:      'Disclosure',
    component: Disclosure,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },
  // privacy policy
  {
    path:      '/privacy-policy',
    name:      'PrivacyPolicy',
    component: PrivacyPolicy,
    props:     true,
  },
];
