<template>
  <div class="recent-activity">
    <v-card>
      <app-card-title title="Recent Activity"></app-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col>
            <v-col v-if="type">
              <p class="font-weight-medium"> {{dateTime}} - {{type}}</p>
              <p v-if="info" class="note">{{info}}</p>
            </v-col>
            <v-col v-else>
              <p class="font-weight-medium"> No recent activity </p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';
import formatDatetime from '@/utils/format-datetime';

export default {
  name:       'RecentActivity',
  components: {
    AppCardTitle,
  },
  props: {
    type: {
      type:     String,
      required: false,
      default:  null,
    },
    date: {
      type:     String,
      required: false,
      default:  null,
    },
    info: {
      type:     String,
      required: false,
      default:  null,
    },
  },
  computed: {
    dateTime() {
      if (this.date) {
        return formatDatetime(this.date);
      }
      return this.date;
    },
  },
};
</script>
