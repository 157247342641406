
import axiosInstance from './intercept';

/* eslint-disable import/prefer-default-export */
export function createLoanProspect(formData) {
  const headers = {
    common: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axiosInstance.post('/v1/prospects', formData, { headers });
}

export function uploadOCRFile(id, formData) {
  const headers = {
    common: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axiosInstance.post(`/v1/prospects/${id}/ocr_documents`, formData, { headers });
}
