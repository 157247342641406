<template>
  <div class="upload-documents">
    <v-container>
      <v-row>
        <v-col cols="12">
          <page-title title="Upload Documents"></page-title>
          <page-sub-title
            v-if="!loanOfferStatus"
            title="Please upload most recent three months of bank statements"
          ></page-sub-title>
          <v-progress-linear
            :indeterminate="true"
            :hidden="!loading"
          ></v-progress-linear>
          <v-form
            id="UploadForm"
            :hidden="loading"
          >
          <document-upload
            title="Upload Documents 1"
            id="documentType1"
            buttonText="Upload Document"
            :documentTypes="docTitles"
          >
          </document-upload>
          <document-upload
            title="Upload Documents 2"
            id="documentType2"
            buttonText="Upload Document"
            :documentTypes="docTitles"
          >
          </document-upload>
          <document-upload
            title="Upload Documents 3"
            id="documentType3"
            buttonText="Upload Document"
            :documentTypes="docTitles"
          >
          </document-upload>
          <v-btn
              @click="submit"
              color="error"
              class="text-right"
              value="submit"
              id="submitDocumentsButton"
              :disabled="canSubmit"
              >
              Submit Documents
          </v-btn>
            <div
              class="error-handling"
              v-if="uploadError"
              >
              <v-alert
                :value="!!uploadError"
                type="error"
                transition="scale-transition"
                >
                <strong>{{uploadError}}</strong>
              </v-alert>
            </div>
            <v-alert
              :value="reviewError"
              type="error"
              transition="scale-transition"
              dismissible
              >
              <strong>There was an error. Please try again
                or reach out to Headway’s underwriting team
              </strong>
            </v-alert>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import PageTitle from '@/components/PageTitle.vue';
import PageSubTitle from '@/components/PageSubTitle.vue';
import DocumentUpload from '@/components/DocumentUpload.vue';
import { mapState } from 'vuex';

export default {
  name:       'UploadDocuments',
  components: {
    PageTitle,
    DocumentUpload,
    PageSubTitle,
  },
  props: {
    loanOfferStatus: String,
    id:              String,
  },
  data() {
    return {
      docTitles: [
        {
          docText:  'Bank Statement',
          docValue: 'bank_statement',
        },
        {
          docText:  'Driver\'s Licence',
          docValue: 'identification_card',
        },
        {
          docText:  'Proof of Ownership',
          docValue: 'proof_of_business_ownership',
        },
        {
          docText:  'Other',
          docValue: 'other',
        },
      ],
      filesArray:  [],
      typeArray:   [],
      uploadError: '',
      reviewError: false,
      loading:     false,
    };
  },
  methods: {
    submit() {
      this.loading = true;

      this.$store.dispatch('documents/upload', this.id)
        .then(() => {
          if (!this.errors) {
            if (this.loanOfferStatus === 'application_incomplete') {
              this.postReview();
            } else {
              this.goToAccount();
            }
          } else {
            datadogRum.addAction('VueHeadwayPartnerPortal.UploadDocuments.Submit.Success');
            this.goToAccount();
          }
        }).catch((err) => {
          this.loading = false;
          this.uploadError = err.response.data;
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.UploadDocuments.Submit.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException('PostDocuments error response', {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
        }).then(() => {
          this.$store.dispatch('documents/flush');
        });
    },
    postReview() {
      this.$store.dispatch('documents/review', this.id)
        .then(() => {
          datadogRum.addAction('VueHeadwayPartnerPortal.UploadDocuments.PostReview.Success');
          this.goToThankYou();
        }).catch((err) => {
          this.loading = false;
          this.reviewError = true;
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.UploadDocuments.PostReview.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException('PostReviews error response', {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
        });
    },
    goToThankYou() {
      this.$router.push({
        path: '/thank-you',
        name: 'ThankYou',
      });
    },
    goToAccount() {
      this.$router.push({
        path: `/accounts/${this.id}`,
        name: 'Account',
      });
    },
  },
  computed: {
    ...mapState('documents', ['files', 'types']),
    canSubmit() {
      const valid = [this.files.length, this.types.length].every(ln => (ln >= 1 && ln <= 3));
      return !valid;
    },
    errors() {
      return (Object.keys(this.uploadError).length) > 0;
    },
  },
  mounted() {
    this.$store.dispatch('documents/flush');
  },
};
</script>
