<template>
  <v-container fluid class="grey lighten-2 fill-height">
    <v-row class="fill-height">
      <v-col class="align-end flexbox" cols="12">
        <img
            src="@/assets/headway-capital-credit-for-small-businesses-black.png"
            alt="Headway Capital: Credit for Small Businesses"
        />
      </v-col>
      <v-col cols="12">
        <div v-html="disclosure.data.body">
          {{ disclosure.data.body }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name:  'Disclosure',
  props: {
    id: {
      type:     String,
      required: true,
    },
  },
  data() {
    return {
      title: 'Offer Summary Disclosure',
    };
  },
  computed: {
    disclosure() {
      return this.$store.state.disclosures.disclosure;
    },
  },
  mounted() {
    this.$store.dispatch('disclosures/flush');
    this.$store.dispatch('disclosures/disclosure', this.id);
  },
};
</script>

<style type="text/css">
  .disclosure-template {
    font-size: 0.8rem;
    padding: 10px 0;
  }

  .disclosure-template .desc {
    margin-bottom:5px;
  }
  .disclosure-template .desc2 .desc2 {
    margin-top: 10px;
  }

  .disclosure-details {
    padding: 10px;
  }

  .disclosure-template .title {
    font-size: 0.9rem !important;
    line-height: 1.2rem;
  }

  .access-to-capital-label {
    font-size: 2rem;
  }

  @media print {
    .v-app-bar { display: none; }
    .app-navigation { display: none; }
    .v-main { padding: 0 !important; }
    .col-sm-3 { width: 100px;}
    .col-sm-6 { width: 50%; }
    .title { display: inline; }
  }
</style>
