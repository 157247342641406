<template>
  <div class="loan-details">
    <v-row>
      <v-col>
        <v-col>
          <span>Application Date:</span>
          {{date_format(loan.application_date)}}
        </v-col>
        <v-col>
          <span>Credit Limit:</span>
          ${{currency_format(loan.credit_limit)}}
        </v-col>
        <v-col>
          <span>Total Payments:</span>
          {{loan.loan_term}}
        </v-col>
      </v-col>
      <v-col>
        <v-col>
          <span>Issue Date:</span>
          {{date_format(loan.issue_date)}}
        </v-col>
        <v-col>
          <span>Monthly Interest Rate:</span>
          {{loan.monthly_interest_rate}}
        </v-col>
        <v-col>
          <span>Repayment Frequency:</span>
          {{loan.repayment}}
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatCurrency from '@/utils/format-currency';
import formatDate from '@/utils/format-date';

export default {
  name:  'LoanDetails',
  props: {
    loan: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  methods: {
    currency_format(number) {
      return formatCurrency(number);
    },
    date_format(date) {
      return formatDate(date);
    },
  },
};
</script>

<style scoped>
span {
  font-weight: bold;
}
</style>
