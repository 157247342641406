import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import * as fromAccount from '@/api/accounts';

const initialState = () => ({
  offer: '',
  error: false,
});

export default {
  namespaced: true,
  state:      initialState(),
  mutations:  {
    addOffer(state, offer) {
      state.offer = offer;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    viewOffers({ commit }, id) {
      return new Promise((resolve, reject) => {
        fromAccount.loanOffers(id).then((resp) => {
          commit('addOffer', resp.data);
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanOffer.ViewOffers.Success');
          resolve(resp);
        }).catch((err) => {
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanOffer.ViewOffers.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException(`${err}:  Found in View Offers Button`, {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
          commit('error', true);
          reject(err);
        });
      });
    },
  },
};
