import axiosInstance from './intercept';

/* eslint-disable import/prefer-default-export */
export function searchAccounts(query) {
  return axiosInstance.get('/v1/accounts/search', { params: { account_search: query } });
}

export function fetchAccount(id) {
  return axiosInstance.get(`/v1/accounts/${id}`);
}

export function email(id) {
  return axiosInstance.post(`/v1/accounts/${id}/emails`, { type: 'loan_offers_deep_link' });
}

export function uploadDocument(id, formData) {
  const headers = {
    common: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axiosInstance.post(`/v1/accounts/${id}/documents`, formData, { headers });
}

export function review(id) {
  const headers = {
    common: {
      'Content-Type': 'application/json',
    },
  };
  return axiosInstance.post(`/v1/accounts/${id}/reviews`, { headers });
}

export function documents(id) {
  return axiosInstance.get(`/v1/accounts/${id}/documents`);
}

export function loanOffers(id) {
  return axiosInstance.post(`/v1/accounts/${id}/loan_offers`, { requestType: 'createRecord' });
}
