import Vue from 'vue';
import Vuex from 'vuex';
import account from '@/store/modules/account';
import loanApplication from '@/store/modules/loanApplication';
import portalUser from '@/store/modules/portalUser';
import documents from '@/store/modules/documents';
import loanOCRApplication from '@/store/modules/loanOCRApplication';
import email from '@/store/modules/email';
import loanOffer from '@/store/modules/loanOffer';
import disclosures from '@/store/modules/disclosures';
import 'whatwg-fetch';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    loanApplication,
    portalUser,
    documents,
    email,
    loanOffer,
    loanOCRApplication,
    disclosures,
  },
});
