export default [
  {
    icon:       'mdi-home',
    text:       'Dashboard',
    link:       '/',
    targetView: 'dashboard',
  },
  {
    icon:       'mdi-view-headline',
    text:       'Application Incomplete',
    link:       '/loan-applications/filter/mfo-application-incomplete',
    targetView: 'mfo_application_incomplete',
  },
  {
    icon:       'mdi-send',
    text:       'In Underwriting',
    link:       '/loan-applications/filter/mfo-in-underwriting',
    targetView: 'mfo_in_underwriting',
  },
  {
    icon:       'mdi-send',
    text:       'Underwriting - Action Required',
    link:       '/loan-applications/filter/mfo-underwriting-action-required',
    targetView: 'mfo_underwriting_action_required',
  },
  {
    icon:       'mdi-account-check',
    text:       'Offer Ready',
    link:       '/loan-applications/filter/mfo-offer-ready',
    targetView: 'mfo_offer_ready',
  },
  {
    icon:       'mdi-clock-outline',
    text:       'Contract Unsigned',
    link:       '/loan-applications/filter/mfo-contract-unsigned',
    targetView: 'mfo_contract_unsigned',
  },
  {
    icon:       'mdi-pencil',
    text:       'Contract Signed - Funding Pending',
    link:       '/loan-applications/filter/mfo-funding-pending',
    targetView: 'mfo_funding_pending',
  },
  {
    icon:       'mdi-check-circle-outline',
    text:       'Issued',
    link:       '/loan-applications/filter/mfo_issued',
    targetView: 'issued',
  },
  {
    icon:       'mdi-close-circle-outline',
    text:       'Declined',
    link:       '/loan-applications/filter/mfo-declined',
    targetView: 'mfo_declined',
  },
  {
    icon:       'mdi-clock-outline',
    text:       'Contract Unsigned',
    link:       '/loan-applications/filter/contract-unsigned',
    targetView: 'contract_unsigned',
  },
  {
    icon:       'mdi-send',
    text:       'In Underwriting',
    link:       '/loan-applications/filter/mfo-in-underwriting',
    targetView: 'in_underwriting',
  },
  {
    icon:       'mdi-alert-octagon',
    text:       'Underwriting - action required',
    link:       '/loan-applications/filter/action-required',
    targetView: 'action_required',
  },
  {
    icon:       'mdi-close-circle-outline',
    text:       'Declined',
    link:       '/loan-applications/filter/declined',
    targetView: 'declined',
  },
];
