<template>
  <div class="application-by-id">
    <page-title title="ApplicationById Component"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name:       'ApplicationById',
  components: {
    PageTitle,
  },
};
</script>
