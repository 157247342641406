<template>
  <div class="loan-offer-details">
    <v-card>
      <app-card-title title="Line of Credit Details"></app-card-title>
      <v-divider></v-divider>
      <v-container>
        <app-card-text
          v-if="text"
          :text="text"
        ></app-card-text>
        <view-offer-button
          v-else-if="account.offersReady"
          :id="account.id"
          @fetchAccount="fetchAccount"
        ></view-offer-button>
        <loan-offers
          v-else-if="account.loanOffers"
          :id="account.id"
          :offers="account.loanOffers"
          :disclosureEnabled="account.disclosureEnabled"
        ></loan-offers>
        <loan-details
          v-else-if="account.loanApplication"
          :loan="account.loanApplication"
        ></loan-details>
        <decline-reasons
          v-if="account.declineReasons"
          :declineReasons="account.declineReasons"
        ></decline-reasons>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';
import AppCardText from '@/components/AppCardText.vue';
import ViewOfferButton from '@/components/ViewOfferButton.vue';
import LoanDetails from '@/components/LoanDetails.vue';
import LoanOffers from '@/components/LoanOffers.vue';
import DeclineReasons from '@/components/DeclineReasons.vue';

export default {
  name:       'LoanOfferDetails',
  components: {
    AppCardTitle,
    AppCardText,
    ViewOfferButton,
    LoanDetails,
    LoanOffers,
    DeclineReasons,
  },
  props: {
    account: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  computed: {
    text() {
      switch (this.account.loanOfferStatus) {
        case 'declined':
          return 'Business doesn’t qualify for a Headway line of credit.';
        case 'cancelled':
          return 'Application has been canceled or withdrawn.';
        case 'pending':
          return 'Application is in underwriting. No loan offer available at this time.';
        case 'application_incomplete':
          return 'Application is incomplete, please provide bank statements for the last 3 months.';
        default:
          return null;
      }
    },
  },
  methods: {
    fetchAccount() {
      this.$emit('fetchAccount');
    },
  },
};
</script>
