<template>
  <div class="app-card-title">
    <v-container fluid class="grey lighten-2 fill-height">
      <v-row class="fill-height">
        <v-col class="align-end flexbox" cols="12">
          <page-sub-title :title="title"></page-sub-title>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageSubTitle from '@/components/PageSubTitle.vue';

export default {
  name:       'AppCardTitle',
  components: {
    PageSubTitle,
  },
  props: {
    title: {
      type:     String,
      required: true,
    },
  },
};
</script>
