<template>
  <div class="page-sub-title">
    <h3 class="text-h5">{{title}}</h3>
  </div>
</template>

<script>
export default {
  name:  'PageSubTitle',
  props: {
    title: {
      type:     String,
      default:  '',
      required: true,
    },
  },
};
</script>
