import Vue from 'vue';
import { VueMaskDirective } from 'v-mask';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import useSentry from './plugins/sentry';
import useDatadogRum from './plugins/datadog';
import App from './App.vue';

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

new Vue({
  mounted: () => {
    useSentry();
    useDatadogRum();
  },
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
