<template>
  <div class="loan-application-form">
    <v-progress-linear :indeterminate="true" :hidden="!loading"></v-progress-linear>
    <v-form ref="form"
      v-model="valid"
      lazy-validation
      :hidden="loading"
    >
      <v-container>
        <v-row>
          <v-col v-if="showStagingAlert" cols="12" sm="9" class="yellow lighten-4">
            <h4 class="blue-grey--text text--darken-1">
              <v-icon>mdi-alert</v-icon>
              Uploaded PDF files are sent to Ocrolus which costs $$$.
              Please be diligent when uploading docs so that our Ocrolus cost
              does not significantly increase.
            </h4>
          </v-col>
          <v-alert
            :value="errorsInSubmission"
            type="error"
            transition="scale-transition"
            dismissible
          >
            {{errorMessage()}}
          </v-alert>
          <v-col cols="12" sm="9">
            <v-text-field
              v-model="model.business_name"
              :rules="businessNameRules"
              label="Business Name"
              id="business_name"
              :error="errorsExtraction('business_name')"
              :error-messages="errorMessages('business_name')"
              required >
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col id="centered_button">
            <v-btn
              color="info"
              @click.native="loader = 'loading4'"
              @click.exact="onButtonClick">
              Enter App Manually
              <template v-slot:loader>
                <span>
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-spacer></v-spacer>
            <em>Faster Decision</em>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.business_industry"
              :rules="[v => !!v || 'Business Industry is required']"
              :items="Industry"
              item-text="IndustryType"
              item-value="IndustryValue"
              label="Business Industry"
              id="business_industry"
              :error="errorsExtraction('business_industry')"
              :error-messages="errorMessages('business_industry')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.loan_purpose"
              :rules="[v => !!v || 'Loan Purpose is required']"
              :items="Purpose"
              item-text="PurposeType"
              item-value="PurposeValue"
              label="Purpose of Line of Credit"
              id="loan_purpose"
              :error="errorsExtraction('loan_purpose')"
              :error-messages="errorMessages('loan_purpose')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.time_in_business"
              :rules="[v => !!v || 'Time in Business is required']"
              :items="BusinessTime"
              item-text="TimeType"
              item-value="TimeValue"
              label="Time in Business"
              id="time_in_business"
              :error="errorsExtraction('time_in_business')"
              :error-messages="errorMessages('time_in_business')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.main_phone_number"
              :rules="mainPhoneNumberRules"
              type="phone"
              label="Primary Phone Number"
              v-mask="'(###) ###-####'"
              hint="Numbers only, dashes inserted automatically"
              id="main_phone_number"
              :error="errorsExtraction('main_phone_number')"
              :error-messages="errorMessages('main_phone_number')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.email_address"
              :rules="emailAddressRules"
              label="Email Address"
              id="email_address"
              hint="This will be Applicant's User Name"
              :error="errorsExtraction('email_address')"
              :error-messages="errorMessages('email_address')"
              persistent-hint
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
        <v-container>
          <v-col cols="12" sm="9">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>Required Stips - Always</v-toolbar-title>
                <v-spacer></v-spacer>
                <h4 class="text-right">
                  <i>Required for ALL submissions</i>
                </h4>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <OCRDocumentUpload
                  title="Application"
                  id="appSignedDocUpload"
                  documentType="app_signed"/>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <OCRDocumentUpload
                  title="3 Months of Business Bank Statements"
                  id="bankStatementDocUpload"
                  :maxFiles=3
                  documentType="bank_statement"/>
                <em>After the fifth of the month, we need the previous month</em>
              </v-card-text>
            </v-card>
          </v-col>
        </v-container>
        <v-divider></v-divider>
      <v-container>
        <v-row>
          <TermsAndConditions @changeChecked="tAndCchecked = $event"/>
          <v-btn
            @click="submit"
            color="error"
            class="text-right"
            id="submit-button"
            :disabled="!canSubmit"
          >
            Submit Application
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>


<script>
import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import { mapGetters } from 'vuex';
import OCRDocumentUpload from '@/components/OCRDocumentUpload.vue';
import TermsAndConditions from '@/components/TermsAndConditions.vue';

export default {
  name:       'LoanOCRApplicationForm',
  components: {
    OCRDocumentUpload,
    TermsAndConditions,
  },
  data: () => ({
    tAndCchecked: false,
    model:        {
      business_name: '',
    },
    valid:              true,
    businessNameRules:  [v => !!v || 'Business Name is required'],
    errorFields:        [],
    errorMsg:           {},
    loading:            false,
    uploadError:        '',
    errorsInSubmission: false,
    Industry:           [
      { IndustryType: 'Accomodation and Food Services', IndustryValue: 'accomodation_and_food_services' },
      { IndustryType: 'Agriculture, Forestry, Fishing and Hunting', IndustryValue: 'agriculture_forestry_fishing_and_hunting' },
      { IndustryType: 'Arts, Entertainment, Recreation', IndustryValue: 'arts_entertainment_recreation' },
      { IndustryType: 'Automative services', IndustryValue: 'automotive_services' },
      { IndustryType: 'Beauty and wellness services', IndustryValue: 'beauty_and_wellness' },
      { IndustryType: 'Building Equipment Contractors', IndustryValue: 'building_equipment_contractors' },
      { IndustryType: 'Cleaning and janitorial services', IndustryValue: 'cleaning_and_janitorial_services' },
      { IndustryType: 'Construction', IndustryValue: 'construction' },
      { IndustryType: 'Educational services', IndustryValue: 'educational_services' },
      { IndustryType: 'Finance and Insurance', IndustryValue: 'finance_and_insurance' },
      { IndustryType: 'Healthcare and Social Assistance', IndustryValue: 'healthcare_and_social_assistance' },
      { IndustryType: 'Information', IndustryValue: 'information' },
      { IndustryType: 'Landscaping, lawncare, and outdoor services', IndustryValue: 'landscaping_lawncare_and_outdoor_services' },
      { IndustryType: 'Management of Companies and Enterprises', IndustryValue: 'management_of_companies_and_enterprises' },
      { IndustryType: 'Manufacturing', IndustryValue: 'manufacturing' },
      { IndustryType: 'Other Services', IndustryValue: 'other_services' },
      { IndustryType: 'Professional, Scientific, Technical Services', IndustryValue: 'professional_scientific_technical_services' },
      { IndustryType: 'Real Estate', IndustryValue: 'real_estate' },
      { IndustryType: 'Retail Trade', IndustryValue: 'retail_trade' },
      { IndustryType: 'Transportation and Warehousing', IndustryValue: 'transportation_and_warehousing' },
      { IndustryType: 'Wholesale Trade', IndustryValue: 'wholesale_trade' },
    ],
    Purpose: [
      { PurposeType: 'Business Expansion', PurposeValue: 'business_expansion' },
      { PurposeType: 'Buy Inventory or Supplies', PurposeValue: 'buy_inventory_supplies' },
      { PurposeType: 'Cash Flow or General Working Capital', PurposeValue: 'cash_flow_general_working_capital' },
      { PurposeType: 'Debt Consolidation', PurposeValue: 'debt_consolidation' },
      { PurposeType: 'Make Payroll', PurposeValue: 'make_payroll' },
      { PurposeType: 'Marketing or Advertising', PurposeValue: 'marketing_advertising' },
      { PurposeType: 'Pay Rent', PurposeValue: 'pay_rent' },
      { PurposeType: 'Purchase Equipment', PurposeValue: 'purchase_equipment' },
      { PurposeType: 'Taxes', PurposeValue: 'taxes' },
      { PurposeType: 'Other', PurposeValue: 'other' },
    ],
    BusinessTime: [
      { TimeType: 'Less than 1 year', TimeValue: 'less_than_1_year' },
      { TimeType: '1 year to 2 years', TimeValue: '1_year_to_2_years' },
      { TimeType: '2 years to 5 years', TimeValue: '2_years_to_5_years' },
      { TimeType: 'More than 5 years', TimeValue: 'more_than_5_years' },
    ],
    mainPhoneNumberRules: [
      v => !!v || 'Primary Phone Number is required',
      v => (v && v.length === 14) || 'Primary Phone Number must be 10 digits',
    ],
    emailAddressRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    onButtonClick() {
      this.$router.push({
        path: '/new-loan-application',
        name: 'LoanApplicationForm',
      });
    },
    submit() {
      if (!this.$refs.form.validate()) { return; }
      this.loading = true;

      this.$store.dispatch('loanOCRApplication/sendApplication', this.$data.model).then(() => {
        this.loading = false;
        datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplicationForm.Submit.Success');
        this.goToOCRSubmitted();
      }).catch((error) => {
        this.handleSubmitErrors(error);
      });
    },
    handleSubmitErrors(error) {
      this.loading = false;
      sentry.captureMessage(`${error}`, { level: 'error', extra: { stack: error.stack } });
      datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplicationForm.Submit.Error', { error: `${error}`, stack: error.stack });

      if (error.response) {
        if (error.response.status !== 412) {
          this.errorsInSubmission = true;
          this.uploadError = error.response.data;
        }
        Raven.captureException('ApplicationSubmission error response', {
          extra: {
            error: {
              code:    error.code,
              message: error.message,
              status:  error.status,
            },
          },
        });
        this.errorFields = Object.keys(error.response.data.errors);

        return;
      }

      if (error.request) {
        this.errorsInSubmission = true;
        this.uploadError = error.response.data;
        Raven.captureException('ApplicationSubmission error request', {
          extra: {
            error: {
              code:    error.code,
              message: error.message,
              status:  error.status,
            },
          },
        });
      }
    },
    goToOCRSubmitted() {
      this.$router.push({
        path: '/ocr-submitted',
        name: 'OCRSubmitted',
      });
    },
    errorsExtraction(val) {
      return this.errorFields.includes(val);
    },
    errorMessages(val) {
      let returnValue = [];
      if (this.errorFields.includes(val)) {
        returnValue = this.errorMsg[val];
      }
      return returnValue;
    },
    errorMessage() {
      if (this.uploadError.error === 'bad_request') {
        return 'There was an error during the file upload. Please try again later or enter the application manually';
      }
      return 'There was an error. Please try again or reach out to Headway’s underwriting team';
    },
  },
  computed: {
    ...mapGetters({
      filesByType: 'loanOCRApplication/filesByType',
    }),
    canSubmit() {
      const hasIsoApplication = (this.filesByType('app_signed').length === 1);
      const hasBankStatement = (this.filesByType('bank_statement').length >= 1);

      return hasIsoApplication && hasBankStatement && this.tAndCchecked;
    },
    showStagingAlert: () => process.env.VUE_APP_ENV === 'staging',
  },
  mounted() {
    this.$store.dispatch('loanOCRApplication/flush');
  },
};
</script>

<style scoped>
#centered_button {
  text-align: center;
  max-width: 300px;
}

.loan-application-form {
  margin-bottom: 32px;
}
</style>
