<template>
  <div class="app-header">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      app
      fixed
    >
      <v-app-bar-nav-icon
        v-if="authenticated"
        class="navbar__button"
        @click.stop="$emit('toggle-nav')"
      ></v-app-bar-nav-icon>
      <!-- There's a known bug in the v-app-bar-title element (https://github.com/vuetifyjs/vuetify/issues/13455)
      The remediation is to keep using v-toolbar-title -->
      <v-toolbar-title class="ml-0 pl-1">
        <span>
          <!-- below is a hack, passing the value via App.vue isn't building a path, needs fix -->
          <img
            src="@/assets/headwayCapitalLogo.png"
            height="20px"
            :alt="brandAltText"
          />
        </span>
        <span class="hidden-xs-only">{{title}}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="authenticated" style="display: flex; align-items: center;">
        <v-col md="8">
          <search-input></search-input>
        </v-col>
        <v-col md="4">
          <v-btn color="info" @click="logout" class="logout-button">Logout</v-btn>
        </v-col>
      </div>
      <!-- add the UID conditional upon user being logged in
      (consider using email address for boolean) -->
    </v-app-bar>
  </div>
</template>

<script>
import SearchInput from '@/components/SearchInput.vue';
import { removeTokens, isAuthenticated } from '@/auth/utils/tokens';

export default {
  name:       'AppHeader',
  components: {
    SearchInput,
  },
  props: {
    title: {
      type:     String,
      default:  '',
      required: true,
    },
    brandLogo: {
      type:     String,
      default:  '',
      required: false,
    },
    brandAltText: {
      type:     String,
      default:  '',
      required: false,
    },
  },
  computed: {
    authenticated() {
      return isAuthenticated();
    },
  },
  methods: {
    logout() {
      removeTokens();
      window.location.reload(true);
    },
  },
};
</script>

<style scoped>
span {
  font-weight: bold;
}
</style>
