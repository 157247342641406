import * as fromDisclosures from '@/api/loanDisclosures';

const initialState = () => ({
  disclosure: {},
  error:      '',
});

export default {
  namespaced: true,
  state:      initialState(),
  mutations:  {
    error(state, payload) {
      if (['bad_request', 'internal_service_error'].includes(payload.error)) {
        state.error = 'There was an error. Please try again or reach out to Headway’s underwriting team';
      } else {
        state.error = 'There was an error. Please try again.';
      }
    },
    pushDisclosure(state, disclosure) {
      state.disclosure = disclosure;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    flush({ commit }) {
      commit('flush');
    },
    disclosure({ commit }, id) {
      fromDisclosures.fetchDisclosure(id).then((response) => {
        commit('pushDisclosure', response);
      });
    },
  },
};
