<template>
  <v-app id="inspire">
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card class="mt-5">
          <app-card-title :title="title"></app-card-title>
          <v-card-text>
            <div class="privacy-policy">
              <p><em>Effective as of May 5, 2023</em></p>
              <p><em>Last Updated May 5, 2023</em></p>
              <h1>Introduction</h1>
              <p>
                Headway Capital, LLC and its parents, subsidiaries, and
                affiliates doing business under the Headway brand (collectively,
                &ldquo;we,&rdquo; &ldquo;our,&rdquo; or &ldquo;us&rdquo;)
                respect the privacy of your information. This Privacy Policy is
                designed to assist you in understanding how we collect, use,
                share, and safeguard your information. This Privacy Policy
                applies to individuals who access this website
                (&ldquo;Site&rdquo;) and the services provided through our Site
                (collectively, &ldquo;Services&rdquo;).
              </p>
              <p>
                From time to time, we may change this Privacy Policy. If we do,
                we will post an amended version on this webpage. Please review
                this Privacy Policy periodically.
              </p>
              <p>This Privacy Policy covers the following topics:</p>
              <ol class="table-of-contents">
                <li>
                  <a href="#information-we-collect-online"
                    >Information We Collect Online</a
                  >
                </li>
                <li>
                  <a href="#how-we-use-your-information"
                    >How We Use Your Information</a
                  >
                </li>
                <li>
                  <a href="#how-we-share-your-information"
                    >How We Share Your Information</a
                  >
                </li>
                <li>
                  <a href="#cookies-and-other-tracking-technologies"
                    >Cookies and Other Tracking Technologies</a
                  >
                </li>
                <li>
                  <a href="#opt-out-preference-signals"
                    >Opt-Out Preference Signals</a
                  >
                </li>
                <li>
                  <a href="#choices-about-your-information"
                    >Choices About Your Information</a
                  >
                </li>
                <li>
                  <a href="#security">Security</a>
                </li>
                <li>
                  <a href="#third-party-links">Third Party Links</a>
                </li>
                <li>
                  <a href="#childrens-privacy">Children&rsquo;s Privacy</a>
                </li>
                <li>
                  <a href="#notice-to-nevada-residents"
                    >Notice to Nevada Residents</a
                  >
                </li>
                <li>
                  <a href="#notice-to-california-residents"
                    >Notice to California Residents</a
                  >
                </li>
                <li>
                  <a href="#accessibility">Accessibility</a>
                </li>
                <li>
                  <a href="#how-to-contact-us">How to Contact Us</a>
                </li>
              </ol>

              <ol class="main-content">
                <li>
                  <h1
                    id="information-we-collect-online"
                    style="display: inline"
                  >
                    Information We Collect Online
                  </h1>
                  <p>
                    We collect Personally Identifiable Information from you
                    through your use of the Site and Services. &ldquo;Personally
                    Identifiable Information&rdquo; is individually identifiable
                    information about an individual that we collect online when
                    they seek our Services on behalf of their business and that
                    we maintain in an accessible form. We collect the following
                    types of Personally Identifiable Information:
                  </p>
                  <h2>Information You Provide</h2>
                  <p>
                    We may collect the following Personally Identifiable
                    Information that you voluntarily provide to us in using our
                    Site and Services:
                  </p>
                  <ul>
                    <li>
                      <em>Undergo due diligence.</em> Before entering into a
                      business relationship, we collect documentation pertaining
                      to the soundness and compliance status of that business
                      which may include Personally Identifiable Information of
                      the business owner such as name, date of birth, home
                      address, email address, last four digits of Social
                      Security Number, as well as non Personally Identifiable
                      Information such as business contact details, proof of
                      insurance, broker license, business tax ID, and other
                      information.*
                    </li>
                    <li>
                      <em>Register for an account via the Site.</em> In order to
                      use the Site, you will create an account with us and
                      provide us with your first and last name and email
                      address.
                    </li>
                  </ul>
                  <p>
                    *When required in the U.S., we will collect information on
                    the beneficial owners of our business customers for purposes
                    of verifying their identity only. The U.S. Government
                    implemented the beneficial ownership regulation to help
                    deter financial crimes. By complying, we are doing our part
                    in upholding the regulation to protect the financial system.
                    Pursuant to our privacy policies and procedures, we will
                    maintain beneficial ownership information in our system of
                    record.
                  </p>
                  <h2>Information as You Navigate Our Site</h2>
                  <p>
                    We automatically collect certain Personally Identifiable
                    Information through your use of the Site and Services, such
                    as the following:
                  </p>
                  <ul>
                    <li>
                      <em>Usage Information.</em> For example, the pages on the
                      Site you access, the frequency of access, and what you
                      click on while on the Site.
                    </li>
                    <li>
                      <em>Device Information.</em> For example, hardware model,
                      operating system, application version number, and browser.
                    </li>
                    <li>
                      <em>Location Information.</em> Location information from
                      Site visitors on a city-regional basis.
                    </li>
                  </ul>
                  <h2>Third Party Information</h2>
                  <p>
                    In some cases, we may receive certain Personally
                    Identifiable Information from you about a third party. For
                    example, in our Site, you may provide the name and phone
                    number of a third party. If you submit any Personally
                    Identifiable Information about another individual to us, you
                    are responsible for making sure you have the authority to do
                    so and to allow us to use their Personally Identifiable
                    Information in accordance with this Privacy Policy for
                    non-customer Personally Identifiable Information and in
                    accordance with our
                    <a href="https://www.headwaycapital.com/privacy-policy"
                      >Customer Privacy Policy</a
                    >
                    for customer Personally Identifiable Information.
                  </p>
                </li>
                <li>
                  <h1 id="how-we-use-your-information" style="display: inline">
                    How We Use Your Information
                  </h1>
                  <p>
                    We use the Personally Identifiable Information we collect to
                    allow you to access the Site, to improve our Site and
                    Services, and to protect our legal rights. In addition, we
                    may use the Personally Identifiable Information we collect
                    to:
                  </p>
                  <ul>
                    <li>
                      Compile, save, use, and analyze your Personally
                      Identifiable Information in both a personally identifiable
                      form and as part of aggregated data;
                    </li>
                    <li>
                      Verify your identity and conduct appropriate due
                      diligence;
                    </li>
                    <li>
                      Register you as a user and identify you when you sign into
                      your account;
                    </li>
                    <li>
                      Process the customer applications you submit and determine
                      whether or not they qualify for our products;
                    </li>
                    <li>Service your account, including issuing payments;</li>
                    <li>
                      Communicate with you regarding your account and
                      applications you&rsquo;ve submitted;
                    </li>
                    <li>
                      Communicate with you about our Site or Services or to
                      inform you of any changes to our Site or Services;
                    </li>
                    <li>Provide support;</li>
                    <li>Maintain and improve our Site and Services;</li>
                    <li>
                      Protect the security and integrity of our Site and
                      Services;
                    </li>
                    <li>
                      Investigate, prevent, or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the physical safety of any person,
                      and to otherwise fulfill our legal obligations;
                    </li>
                    <li>
                      Monitor compliance with and enforce this Privacy Policy
                      and any other applicable agreements and policies;
                    </li>
                    <li>Defend our legal rights and the rights of others;</li>
                    <li>
                      Fulfill any other purposes for which you provide it, or
                      any other purpose we deem necessary or appropriate;
                    </li>
                    <li>Efficiently maintain our business;</li>
                    <li>Comply with applicable law; and</li>
                    <li>
                      Produce data analytics and reports containing
                      de-identified summaries of Personally Identifiable
                      Information and other information that is not Personally
                      Identifiable Information (such information, &ldquo;General
                      Information&rdquo;) that we share with business partners.
                      General Information may include Personally Identifiable
                      Information that has been aggregated and will not identify
                      you.
                    </li>
                  </ul>
                </li>
                <li>
                  <h1
                    id="how-we-share-your-information"
                    style="display: inline"
                  >
                    How We Share Your Information
                  </h1>
                  <p>
                    We may share the information that we collect about you in
                    the following ways:
                  </p>
                  <ul>
                    <li>
                      As a subsidiary of Enova International, Inc.
                      (&ldquo;Enova&rdquo;), with Enova or its subsidiaries;
                    </li>
                    <li>Third-party lenders that we partner with;</li>
                    <li>
                      With vendors who perform data or Site-related services on
                      our behalf (e.g., email, hosting, maintenance, backup,
                      analysis, etc.);
                    </li>
                    <li>To the extent that we are required to do so by law;</li>
                    <li>
                      In connection with any legal proceedings or prospective
                      legal proceedings;
                    </li>
                    <li>
                      To establish, exercise, or defend our or a third
                      party&rsquo;s legal rights, including providing
                      information to others for the purposes of fraud
                      prevention;
                    </li>
                    <li>
                      With any person who we reasonably believe may apply to a
                      court or other competent authority for disclosure of that
                      Personally Identifiable Information where, in our
                      reasonable opinion, such court or authority would be
                      reasonably likely to order disclosure of that Personally
                      Identifiable Information;
                    </li>
                    <li>
                      With any other person or entity as part of any business or
                      asset sale, equity transaction, merger, acquisition,
                      bankruptcy, liquidation, or similar proceeding, or in
                      preparation for any of these events;
                    </li>
                    <li>
                      With any other person or entity where you consent to the
                      disclosure; and
                    </li>
                    <li>
                      For any other purpose disclosed by us when you provide the
                      Personally Identifiable Information or for any other
                      purpose we deem necessary, including to protect the health
                      or safety of others.
                    </li>
                  </ul>
                </li>
                <li>
                  <h1
                    id="cookies-and-other-tracking-technologies"
                    style="display: inline"
                  >
                    Cookies and Other Tracking Technologies
                  </h1>
                  <h2>How We Use Cookies</h2>
                  <p>
                    Like many other companies, we use cookies and other tracking
                    technologies (such as pixels and web beacons) (collectively,
                    &ldquo;Cookies&rdquo;). Cookies are small files of
                    information that are stored by your web browser software on
                    your computer hard drive, mobile or other devices (e.g.,
                    smartphones or tablets).
                  </p>
                  <p>We use Cookies to:</p>
                  <ul>
                    <li>Estimate audience size and usage patterns;</li>
                    <li>
                      Understand and save your preferences for future visits,
                      allowing us to customize the Site and Services to your
                      individual needs;
                    </li>
                    <li>
                      Compile aggregate data about site traffic and site
                      interactions to resolve issues and offer better site
                      experiences and tools in the future; and
                    </li>
                    <li>Recognize when you return to the Site.</li>
                  </ul>
                  <p>
                    We set some Cookies ourselves, while separate entities set
                    other Cookies. We use Cookies other entities set to provide
                    us with useful information and to help us improve our Site
                    and Services. For example, we use Cookies from Google and
                    other similar companies.
                  </p>
                  <h2>How You Can Opt Out of Cookies</h2>
                  <p>
                    You can block Cookies by changing your Internet browser
                    settings. You can find out more about Cookies and how to
                    manage them by visiting
                    <a href="http://www.aboutcookies.org/"
                      >www.AboutCookies.org</a
                    >
                    or
                    <a href="http://www.allaboutcookies.org"
                      >www.allaboutcookies.org</a
                    >.
                  </p>
                  <h2>Platform Controls</h2>
                  <p>
                    You can opt out of Cookies set by specific entities by
                    following the instructions found at these links:
                  </p>
                  <ul>
                    <li>
                      Adobe:
                      <a href="https://www.adobe.com/privacy/opt-out.html"
                        >https://www.adobe.com/privacy/opt-out.html</a
                      >
                    </li>
                    <li>
                      Google:
                      <a href="https://adssettings.google.com"
                        >https://adssettings.google.com</a
                      >
                    </li>
                  </ul>
                  <h2>Advertising Industry Resources</h2>
                  <p>
                    You can understand which entities have currently enabled
                    Cookies for your browser or mobile device and how to opt out
                    of some of those Cookies by accessing the
                    <a href="http://optout.networkadvertising.org/#!/"
                      >Network Advertising Initiative&rsquo;s website</a
                    >
                    or the
                    <a href="http://optout.aboutads.info/#!/"
                      >Digital Advertising Alliance&rsquo;s website</a
                    >. For more information on mobile specific opt-out choices,
                    visit the<a
                      href="http://www.networkadvertising.org/mobile-choices"
                      >Network Advertising Initiative&rsquo;s Mobile Choices
                      website</a
                    >.
                  </p>
                  <p>
                    Please note these opt-out mechanisms are specific to the
                    device or browser on which they are exercised. Therefore,
                    you will need to opt out on every browser and device that
                    you use.
                  </p>
                  <h2>Google Analytics</h2>

                  <p>
                    We use Google Analytics, a web analytics service provided by
                    Google, Inc. Google Analytics uses Cookies or other tracking
                    technologies to help us analyze how users interact with the
                    Site and Services, compile reports on their activity, and
                    provide other services related to their activity and usage.
                    The technologies used by Google may collect information such
                    as your IP address, time of visit, whether you are a
                    returning visitor, and any referring website. The
                    information generated by Google Analytics will be
                    transmitted to and stored by Google and will be subject to
                    Google&rsquo;s
                    <a href="https://policies.google.com/privacy"
                      >privacy policies</a
                    >. To learn more about Google&rsquo;s partner services and
                    to learn how to opt out of tracking of analytics by Google,
                    click
                    <a
                      href="https://policies.google.com/technologies/partner-sites"
                      >here</a
                    >.
                  </p>
                  <h2>Hotjar</h2>
                  <p>
                    We use Hotjar to better understand our users&rsquo; needs
                    and to optimize the service and experience. Hotjar is a
                    technology service that helps us better understand our
                    users&rsquo; experience (e.g., how much time they spend on
                    which pages, which links they choose to click, what users do
                    and do not like, etc.) and this enables us to build and
                    maintain our service with user feedback. Hotjar uses cookies
                    and other technologies to collect data on our users&rsquo;
                    behavior and their devices. This includes a device&rsquo;s
                    IP address (processed during your session and stored in a
                    de-identified form), device screen size, device type (unique
                    device identifiers), browser information, geographic
                    location (country only), and the preferred language used to
                    display our Site. Hotjar stores this information on our
                    behalf in a pseudonymized user profile. Hotjar is
                    contractually forbidden to sell any of the data collected on
                    our behalf. For more information, please view Hotjar&rsquo;s
                    privacy page by clicking
                    <a href="https://www.hotjar.com/privacy/">here</a>.
                  </p>
                  <h2>Other Types of Collecting Activities</h2>

                  <p>
                    The Site and Services may use Cookies and similar
                    technologies third-party vendors provide to collect
                    information on user behavior (e.g., screens and pages
                    visited, buttons and links clicked, limited information
                    entered, and user taps and mouse movements). This
                    information enables us to monitor and improve the user
                    experience.
                  </p>
                </li>
                <li>
                  <h1 id="opt-out-preference-signals" style="display: inline">
                    Opt-Out Preference Signals
                  </h1>
                  <p>
                    The Site recognizes the Global Privacy Control (“GPC”)
                    signal. If you are using a browser setting or plug-in that
                    sends an opt-out preference signal to each website you
                    visit, we will treat that as a valid request to opt out and
                    this will be indicated in the banner notice that appears
                    when you visit our website. You can manually indicate your
                    preferences via the Do Not Sell or Share My Personal Information
                    link at the bottom of the website. To download and use a
                    browser supporting the GPC browser signal, click here:
                    <a href="https://globalprivacycontrol.org/orgs"
                      >https://globalprivacycontrol.org/orgs</a
                    >. If you choose to use the GPC signal, you will need to
                    turn it on for each supported browser or browser extension
                    you use.
                  </p>
                  <p>
                    Some internet browsers incorporate a &ldquo;Do Not
                    Track&rdquo; feature that signals to websites you visit that
                    you do not want to have your online activity tracked. Given
                    that there is not a uniform way that browsers communicate
                    the &ldquo;Do Not Track&rdquo; signal, the Site does not
                    currently interpret, respond to or alter its practices when
                    it receives &ldquo;Do Not Track&rdquo; signals.
                  </p>
                </li>
                <li>
                  <h1
                    id="choices-about-your-information"
                    style="display: inline"
                  >
                    Choices About Your Information
                  </h1>
                  <h2>Review and Request Changes to Your Information</h2>
                  <p>
                    You may use your account to access, correct, or view certain
                    Personally Identifiable Information we have collected and
                    which is associated with your account. To review or request
                    changes to any of your Personally Identifiable Information,
                    please contact us at
                    <a href="mailto:smbbd@enova.com">smbbd@enova.com</a> or
                    write to us at:
                  </p>
                  <p>
                    Enova SMB<br />
                    Business Development<br />
                    4700 W. Daybreak Pkwy., Suite 200<br />
                    South Jordan, UT 84009
                  </p>
                </li>
                <li>
                  <h1 id="security" style="display: inline">Security</h1>
                  <p>
                    We maintain commercially reasonable security measures to
                    protect the Personally Identifiable Information we collect
                    and store from loss, misuse, destruction, or unauthorized
                    access. However, no security measure or modality of data
                    transmission over the Internet is 100% secure. Although we
                    strive to use commercially acceptable means to protect your
                    Personally Identifiable Information, we cannot guarantee
                    absolute security.
                  </p>
                </li>
                <li>
                  <h1 id="third-party-links" style="display: inline">
                    Third Party Links
                  </h1>
                  <p>
                    The Site and Services may contain links that will let you
                    leave the Site and Services and access another website.
                    Linked websites are not under our control. Except as stated
                    below, this Privacy Policy applies solely to Personally
                    Identifiable Information that is acquired by us on this Site
                    and Services. We accept no responsibility or liability for
                    these other websites.
                  </p>
                </li>
                <li>
                  <h1 id="childrens-privacy" style="display: inline">
                    Children&rsquo;s Privacy
                  </h1>
                  <p>
                    The Site and Services are not intended for children under 13
                    years of age. We do not knowingly collect, use, or disclose
                    personal information from children under 13.
                  </p>
                </li>
                <li>
                  <h1 id="notice-to-nevada-residents" style="display: inline">
                    Notice to Nevada Residents
                  </h1>
                  <p>
                    Nevada law allows Nevada residents to opt out of the sale of
                    certain types of personal information. Subject to several
                    exceptions, Nevada law defines &ldquo;sale&rdquo; to mean
                    the exchange of certain types of personal information for
                    monetary consideration to another person. We do not
                    currently sell personal information as defined in the Nevada
                    law. However, if you are a Nevada resident, you still may
                    submit a verified request to opt out of sales and we will
                    record your instructions and incorporate them in the future
                    if our policy changes. You may send opt-out requests to
                    <a href="mailto:smbbd@enova.com">smbbd@enova.com</a>.
                  </p>
                </li>
                <li>
                  <h1
                    id="notice-to-california-residents"
                    style="display: inline"
                  >
                    Notice to California Residents
                  </h1>
                  <p>
                    The California Consumer Privacy Act, as amended by the
                    California Privacy Rights Act of 2020 (&ldquo;CCPA&rdquo;),
                    requires that we provide California residents with a privacy
                    policy that contains a comprehensive description of our
                    online and offline practices regarding the collection, use,
                    disclosure, sale, sharing, and retention of personal
                    information and of the rights of California residents
                    regarding their personal information. This section of the
                    Privacy Policy is intended solely for, and is applicable
                    only as to, California residents. If you are not a
                    California resident, this section does not apply to you and
                    you should not rely on it.
                  </p>
                  <p>
                    The CCPA defines &ldquo;personal information&rdquo; to mean
                    information that identifies, relates to, describes, is
                    reasonably capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a
                    particular California resident or household. Personal
                    information does not include publicly available,
                    deidentified or aggregated information or lawfully obtained,
                    truthful information that is a matter of public concern. For
                    purposes of this &ldquo;Notice to California
                    Residents&rdquo; section we will refer to this information
                    as &ldquo;Personal Information.&rdquo;
                  </p>
                  <h2>Notice at Collection of Personal Information</h2>
                  <p>
                    We currently collect and, in the 12 months prior to the Last
                    Updated date of this Privacy Policy, have collected the
                    following categories of Personal Information:
                  </p>
                  <ul>
                    <li>
                      Identifiers (name, postal address, online identifier,
                      Internet Protocol address, email address, last four digits
                      of Social Security Number)
                    </li>
                    <li>
                      Unique personal identifiers (device identifier; cookies,
                      beacons, pixel tags, mobile ad identifiers, or other
                      similar technology)
                    </li>
                    <li>Telephone number</li>
                    <li>Signature</li>
                    <li>Bank account number</li>
                    <li>
                      Other financial information, including tax identification
                      number
                    </li>
                    <li>
                      Internet or other electronic network activity information
                      (browsing history; search history; and information
                      regarding website interaction)
                    </li>
                    <li>Geolocation data</li>
                    <li>Professional or employment-related information</li>
                    <li>
                      Characteristics of protected classifications under
                      California or federal law (age 40 and older)
                    </li>
                    <li>
                      Audio or similar information (recordings for monitoring
                      and training purposes)
                    </li>
                  </ul>

                  <p>
                    We collect Personal Information directly from our business
                    partners who are California residents.
                  </p>
                  <p>
                    In addition to the purposes stated above in the Section
                    &ldquo;How We Use Your Information&rdquo; we currently
                    collect and have collected the above categories of Personal
                    Information for the following business or commercial
                    purposes:
                  </p>
                  <ul>
                    <li>
                      Helping to ensure security and integrity to the extent the
                      use of your Personal Information is reasonably necessary
                      and proportionate for these purposes
                    </li>
                    <li>
                      Entering into and fulfilling the obligations of our
                      business partner relationships
                    </li>
                    <li>
                      Debugging to identify and repair errors that impair
                      existing intended functionality
                    </li>
                    <li>
                      Performing services, including maintaining or servicing
                      accounts, processing transactions, and verifying
                      information
                    </li>
                    <li>
                      Undertaking activities to verify or maintain the quality
                      or safety of a service and to improve, upgrade, or enhance
                      the service
                    </li>
                    <li>
                      Advancing our commercial or economic interests, such as by
                      inducing another person to buy, rent, lease, join,
                      subscribe to, provide, or exchange products, goods,
                      property, information, or services, or enabling or
                      effecting, directly or indirectly, a commercial
                      transaction
                    </li>
                  </ul>
                  <h2>Sale, Sharing, and Disclosure of Personal Information</h2>
                  <p>
                    The CCPA defines &ldquo;sale&rdquo; as the transfer of
                    Personal Information for monetary or other valuable
                    consideration. Although we do not &ldquo;sell&rdquo;
                    Personal Information as that term may be commonly
                    interpreted, we engage in online activities that may
                    constitute a sale or a share of Personal Information under
                    California law.
                  </p>
                  <p>
                    The following table identifies the categories of Personal
                    Information that we sold or shared to third parties in the
                    12 months preceding the Last Updated date of this Privacy
                    Policy and, for each category, the categories of third
                    parties to whom we sold or shared Personal Information:
                  </p>
                  <table>
                    <tr>
                      <th><p>Category of Personal Information</p></th>
                      <th><p>Categories of Third Parties</p></th>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Unique personal identifiers (device identifier;
                          cookies, beacons, pixel tags, mobile ad identifiers,
                          or other similar technology)
                        </p>
                      </td>
                      <td>
                        <p>
                          Enova and subsidiaries; data analytics provider, data
                          storage providers; security, anti-fraud, and
                          verification providers
                        </p>
                      </td>
                    </tr>
                  </table>

                  <p>
                    We sold or shared Personal Information to third parties to
                    advance our commercial and economic interests.
                  </p>
                  <p>
                    The following table identifies the categories of Personal
                    Information that we disclosed for a business purpose in the
                    12 months preceding the Last Updated date of this Privacy
                    Policy and, for each category, the categories of recipients
                    to whom we disclosed Personal Information.
                  </p>
                  <table>
                    <tr>
                      <th><p>Category of Personal Information</p></th>
                      <th><p>Categories of Recipients</p></th>
                    </tr>
                    <tr>
                      <td><p>Identifiers (name, postal address)</p></td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; lenders;
                          data storage providers; mailing providers; security,
                          anti-fraud, and verification providers; banks; law
                          firms
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>Identifiers (email address)</p></td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; lenders;
                          data storage providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>Telephone number</p></td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; data
                          storage providers; mailing providers; law firms
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>Signature</p></td>
                      <td>
                        <p>
                          Enova and subsidiaries; data storage providers;
                          security, anti-fraud, and security providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>Bank account number</p></td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; data
                          storage providers; banks; law firms
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Internet or other electronic network activity
                          information (browsing history; search history; and
                          information regarding consumer&rsquo;s interaction
                          with website, application or advertisement)
                        </p>
                      </td>
                      <td>
                        <p>Security, anti-fraud, and verification providers</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Other financial information, including a tax
                          identification number or Social Security Number for
                          individual/sole proprietor or single-member LLC
                          entities
                        </p>
                      </td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; data
                          storage providers; law firms, security, anti-fraud,
                          and verification providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td><p>Geolocation data</p></td>
                      <td>
                        <p>
                          Data storage providers; security, anti-fraud, and
                          verification providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Professional or employment-related information</p>
                      </td>
                      <td>
                        <p>
                          Enova and subsidiaries; business partners; data
                          storage providers; security, anti-fraud, and
                          verification providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Characteristics of protected classifications under
                          California or federal law (age 40 and older)
                        </p>
                      </td>
                      <td>
                        <p>
                          Enova and subsidiaries; data storage providers;
                          security, anti-fraud, and verification providers
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Audio or similar information (recordings for
                          monitoring and training purposes)
                        </p>
                      </td>
                      <td><p>Customer support providers</p></td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Inferences drawn from above information to create a
                          profile about you reflecting the consumer&rsquo;s
                          preferences, characteristics, psychological trends,
                          behavior, and abilities
                        </p>
                      </td>
                      <td>
                        <p>Security, anti-fraud, and verification providers</p>
                      </td>
                    </tr>
                  </table>

                  <p>
                    We disclosed Personal Information for the following business
                    or commercial purposes:
                  </p>
                  <ul>
                    <li>
                      Helping to ensure security and integrity to the extent the
                      use of your Personal Information is reasonably necessary
                      and proportionate for these purposes
                    </li>
                    <li>
                      Performing services, including maintaining or servicing
                      accounts, processing or fulfilling orders and
                      transactions, verifying information, and processing
                      payments
                    </li>
                    <li>Advancing our commercial or economic interests</li>
                  </ul>
                  <p>
                    We do not knowingly collect, sell, or share the Personal
                    Information of consumers under 16 years of age. We do not
                    collect or process sensitive personal information for the
                    purpose of inferring characteristics about individuals.
                  </p>
                  <h2>Retention of Personal Information</h2>
                  <p>
                    We retain your Personal Information for as long as your
                    account is active or as necessary to fulfill the purposes
                    for which we collect it, such as to provide you with the
                    service you have requested, and for the purpose of
                    satisfying any legal, accounting, contractual, or reporting
                    requirements that apply to us.
                  </p>
                  <h2>Your Rights</h2>
                  <p>
                    If you are a California resident, you have the following
                    rights with respect to your Personal Information:
                  </p>
                  <ol class="your-rights">
                    <li>
                      The right to know what Personal Information we have
                      collected about you, including the categories of Personal
                      Information, the categories of sources from which we
                      collected Personal Information, the business or commercial
                      purpose for collecting, selling or sharing Personal
                      Information (if applicable), the categories of third
                      parties to whom we disclose Personal Information (if
                      applicable), and the specific pieces of Personal
                      Information we collected about you;
                    </li>
                    <li>
                      The right to delete Personal Information that we collected
                      from you, subject to certain exceptions;
                    </li>
                    <li>
                      The right to correct inaccurate Personal Information that
                      we maintain about you;
                    </li>
                    <li>
                      If we sell or share Personal Information, the right to opt
                      out of the sale or sharing;
                    </li>
                    <li>
                      If we use or disclose sensitive Personal Information for
                      purposes other than those allowed by the CCPA and its
                      regulations, the right to limit our use or disclosure; and
                    </li>
                    <li>
                      The right not to receive discriminatory treatment by us
                      for the exercise of privacy rights the CCPA confers.
                    </li>
                  </ol>
                  <h2>
                    How to Submit a Request to Know, Delete, and/or Correct
                  </h2>
                  <p>
                    You may submit a request to know, delete, and/or correct by
                    emailing us at
                    <a href="mailto:smbbd@enova.com">smbbd@enova.com</a> or by
                    calling us toll free at 1-888-994-1389.
                  </p>
                  <p>
                    If you are submitting a request on behalf of a California
                    resident, please submit the request through one of the
                    designated methods discussed above. After submitting the
                    request, we will require additional information to verify
                    your authority to act on behalf of the California resident.
                  </p>
                  <p>
                    <em
                      >Our Process for Verifying a Request to Know, Delete,
                      and/or Correct</em
                    >
                  </p>
                  <p>
                    We will comply with your request upon verification of your
                    identity and, to the extent applicable, the identity of the
                    California resident on whose behalf you are making such
                    request.
                  </p>
                  <p>
                    We will verify your identity either to a &ldquo;reasonable
                    degree of certainty&rdquo; or a &ldquo;reasonably high
                    degree of certainty&rdquo; depending on the sensitivity of
                    the Personal Information and the risk of harm to you by
                    unauthorized disclosure, deletion, or correction as
                    applicable.
                  </p>
                  <p>
                    For requests to access categories of Personal Information
                    and for requests to delete or correct Personal Information
                    that is not sensitive and does not pose a risk of harm by
                    unauthorized deletion or correction, we will verify your
                    identity to a &ldquo;reasonable degree of certainty&rdquo;
                    by verifying at least two data points that you previously
                    provided to us and which we have determined to be reliable
                    for the purpose of verifying identities.
                  </p>
                  <p>
                    For requests to access specific pieces of Personal
                    Information or for requests to delete or correct Personal
                    Information that is sensitive and poses a risk of harm by
                    unauthorized deletion or correction, we will verify your
                    identity to a &ldquo;reasonably high degree of
                    certainty&rdquo; by verifying at least three pieces of
                    Personal Information you previously provided to us and which
                    we have determined to be reliable for the purpose of
                    verifying identities. In addition, we may request that you
                    submit a signed declaration under penalty of perjury stating
                    that you are the individual whose Personal Information is
                    being requested.
                  </p>
                  <p>
                    To verify your request, we will match the personal
                    information you submit with the information we have about
                    you. If we cannot conclude your information matches what we
                    have, you may be directed to contact us with further
                    instructions to assist us in responding to your request. To
                    verify your request, we may ask you to submit the following
                    information:
                  </p>
                  <ul>
                    <li>Your first and last name;</li>
                    <li>
                      The last 4 digits of your Social Security number or the
                      full tax identification number of your company; and
                    </li>
                    <li>The name of your business</li>
                  </ul>
                  <h2>
                    Right to Opt Out of Sale or Sharing of Personal Information
                  </h2>
                  <p>
                    If you are a California resident, you have the right to
                    direct us to stop selling or sharing your Personal
                    Information.
                  </p>
                  <p>
                    You may submit a request to opt out of sales or sharing by
                    clicking on our website footer link:
                    <a
                      href="#"
                      onclick="Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'); return false;"
                      >&ldquo;Do Not Sell or Share My Personal
                      Information.&rdquo;</a
                    > If you have enabled privacy controls on your browser
                    (such as a plugin), we will also treat that as a valid
                    request to opt out.
                  </p>
                  <h2>Shine the Light Law</h2>
                  <p>
                    We do not disclose personal information obtained through our
                    Site or Services to third parties for their direct marketing
                    purposes. Accordingly, we have no obligations under
                    California Civil Code &sect; 1798.83.
                  </p>
                </li>
                <li>
                  <h1 id="accessibility" style="display: inline">
                    Accessibility
                  </h1>
                  <p>
                    We are committed to ensuring this Privacy Policy is
                    accessible to individuals with disabilities. If you wish to
                    access this Privacy Policy in an alternative format, please
                    contact us as described below.
                  </p>
                </li>
                <li>
                  <h1 id="how-to-contact-us" style="display: inline">
                    How to Contact Us
                  </h1>
                  <p id="how-to-contact-us-5">
                    To contact us for questions or concerns about our privacy
                    policies or practices please email us at
                    <a href="mailto:smbbd@enova.com">smbbd@enova.com</a> or
                    write to us at:
                  </p>
                  <p>
                    Enova SMB<br />
                    Business Development<br />
                    4700 W. Daybreak Pkwy., Suite 200<br />
                    South Jordan, UT 84009
                  </p>
                </li>
              </ol>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';

export default {
  name:       'PrivacyPolicy',
  components: {
    AppCardTitle,
  },
  data() {
    return {
      title: 'Privacy Policy',
    };
  },
};
</script>

<style lang="scss">
.v-main {
  margin-bottom: 64px;
}
.privacy-policy {
  text-align: justify;
  ol {
    margin: 20px 0;
    &.table-of-contents {
      font-variant: small-caps;
    }
    &.your-rights {
      counter-reset: list;
      li {
        counter-increment: list;
        margin: 10px;
        &::marker {
          content: "(" counter(list, decimal) ")\a0";
        }
      }
    }
    &.main-content {
      padding: 0;
      list-style-position: inside;
      > li {
        &::marker {
          font-size: 1.3em;
          font-weight: bold;
          padding-left: 10px;
        }
      }
      ol,
      ul {
        list-style-position: outside;
      }
    }
  }
  h1 {
    margin: 20px 0 0;
    font-size: 1.3em;
    font-variant: small-caps;
    scroll-margin-top: 75px;
  }
  h2 {
    font-size: 1em;
    margin: 10px 0 0;
  }
  ul {
    margin: 10px 0;
    list-style-type: disc;
  }
  table {
    border-collapse: collapse;
    margin: 10px 0;
    tr {
      th {
        background: #aaaaaa;
      }
      td,
      th {
        border: 1px solid #000;
        padding: 16px;
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>
