<template>
  <v-app id="inspire">
    <v-card-title>
      <v-row>
        <v-col class="center" cols="12" sm="6" offset-sm="2">
          <v-icon size="800%" color="success">mdi-check</v-icon>
          <div>
            <h2>{{this.title}}</h2>
            <h3>
              We will reach out to you with a decision within 1 business day.
            </h3>
          </div>
        </v-col>
        <v-col>
          <v-btn
            v-if="newLoanApplicationPermission"
            color="info"
            @click.native="loader = 'loading4'"
            @click.exact="onButtonClick"
            class="new-loan-application"
          >
            New Application
            <template v-slot:loader>
              <span>
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OCRSubmitted',
  data() {
    return {
      title: 'Application Submitted',
    };
  },
  computed: {
    ...mapState('portalUser', ['permissions']),
    dashboard() {
      return this.permissions.dashboard;
    },
    newLoanApplicationOCRPermission() {
      return this.permissions.appOcr;
    },
    newApplication() {
      return (this.dashboard[0] || {}).target_view === 'new_application';
    },
    newLoanApplicationPermission() {
      if (!this.dashboard || !this.dashboard.length) { return false; }
      return this.newApplication;
    },
  },
  methods: {
    onButtonClick() {
      if (this.newLoanApplicationOCRPermission) {
        this.$router.push({
          path: '/new-loan-ocr-application',
          name: 'LoanOCRApplicationForm',
        });
        return;
      }

      this.$router.push({
        path: '/new-loan-application',
        name: 'LoanApplicationForm',
      });
    },
    mounted() {
      this.$store.dispatch('portalUser/getPermissions');
    },
  },
};
</script>

<style scoped>
.center {
    text-align: center;
    color: #387002;
}
</style>
