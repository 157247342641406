<template>
  <div class="documents-list">
    <v-card>
      <app-card-title title="Documents Center"></app-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row v-if="documents.length">
          <v-col>
            <table style="width:100%" align="left">
              <tr align="left">
                <th>File Name</th>
                <th>Document Type</th>
                <th>Upload Time</th>
              </tr>
              <tr v-for="document in documents" :key="document.index" align="left">
                <td>{{document.attachmentName}}</td>
                <td>{{document.attachmentType}}</td>
                <td>{{document.receivedTime}}</td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row v-if="loanOfferStatus === 'application_incomplete'">
          <v-col>
            <b>
              <app-card-text
                text="To complete your application,
                please upload your documents by pressing the button below"
                >
              </app-card-text>
            </b>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-col>
              <v-btn
                @click="submit"
                color="error"
                class="text-right"
                >
                Upload Documents
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';
import AppCardText from '@/components/AppCardText.vue';
import { mapState } from 'vuex';

export default {
  name:       'DocumentsList',
  components: {
    AppCardTitle,
    AppCardText,
  },
  props: {
    loanOfferStatus: {
      type:     String,
      required: false,
    },
    id: {
      type:     String,
      required: true,
    },
  },
  computed: {
    ...mapState('documents', ['documents']),
  },
  methods: {
    submit() {
      this.$router.push({
        path:   `/accounts/${this.id}/upload-documents`,
        name:   'UploadDocuments',
        params: {
          loanOfferStatus: this.loanOfferStatus,
        },
      });
    },
  },
  mounted() {
    this.$store.dispatch('documents/documents', this.id);
  },
};
</script>
