import axios from 'axios';

const clientID = process.env.VUE_APP_LOGIN_SERVER_CLIENT_ID;
const clientSecret = process.env.VUE_APP_OAUTH_CLIENT_SECRET;
const loginServerUrl = process.env.VUE_APP_LOGIN_SERVER_URI;

export function login({ username, password }) {
  const headers = {
    Authorization:  `Basic ${btoa(`${clientID}:${clientSecret}`)}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    'Client-ID':    clientID,
  };

  const instance = axios.create({ headers });

  delete instance.defaults.headers.Accept;

  const credentials = {
    username,
    password,
    grant_type: 'password',
  };

  return instance.post(`${loginServerUrl}/token`, new URLSearchParams(credentials));
}

export function createNewToken(refreshToken) {
  const data = {
    client_id:     clientID,
    grant_type:    'refresh_token',
    refresh_token: refreshToken,
  };

  const headers = {
    'Content-Type': 'application/json',
    'Client-ID':    clientID,
  };

  const instance = axios.create({ headers });

  delete instance.defaults.headers.Accept;
  return instance.post(`${loginServerUrl}/token`, data);
}
