import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import * as fromPermissions from '@/api/permissions';
import menuItems from './menuItems';

const initialState = () => ({
  permissions: {
    id:             null,
    dashboard:      [],
    navigationMenu: [],
    password:       null,
    role:           null,
    source:         null,
    username:       null,
    appOcr:         null,
  },
});

export default {
  namespaced: true,
  state:      initialState(),
  getters:    {
    validMenuItems(state) {
      return menuItems
        // eslint-disable-next-line
        .filter(item => state.permissions.navigationMenu.find(perm => perm.target_view === item.targetView));
    },
  },
  mutations: {
    updatePermissions(state, permissions) {
      state.permissions = permissions;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    getPermissions({ commit }) {
      return new Promise((resolve, reject) => {
        fromPermissions.getPermissions().then((permissions) => {
          const perms = {
            id:             permissions.data.id,
            dashboard:      permissions.data.dashboard,
            navigationMenu: permissions.data.navigation_menu,
            password:       permissions.data.password,
            role:           permissions.data.role,
            source:         permissions.data.source,
            username:       permissions.data.username,
            appOcr:         permissions.data.ocr_app_enabled,
          };
          commit('updatePermissions', perms);
          datadogRum.addAction('VueHeadwayPartnerPortal.PortalUser.GetPermissions.Success');
          resolve();
        }).catch((err) => {
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.PortalUser.GetPermissions.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException(`${err}:  Permissions fetch issue`, {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
          reject();
        });
      });
    },
  },
};
