<template>
  <v-app id="inspire">
    <app-navigation
      :items="permittedMenuItems"
      ref="appNavigation"
    ></app-navigation>
    <app-header
      brandLogo="@/assets/headwayCapitalLogo.png"
      brandAltText="Headway Capital"
      title="Partner Portal"
      @toggle-nav="toggleNavigation"
    ></app-header>
    <v-main>
      <router-view/>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppNavigation from '@/components/AppNavigation.vue';
import { isAuthenticated } from '@/auth/utils/tokens';

export default {
  components: {
    AppHeader,
    AppNavigation,
    AppFooter,
  },
  data() {
    return {
      dialog:          false,
      drawer:          true,
      menuPermissions: [],
      currentRoute:    'login',
    };
  },
  computed: {
    permittedMenuItems() {
      if (!isAuthenticated()) { return []; }
      return this.$store.getters['portalUser/validMenuItems'];
    },
  },
  methods: {
    toggleNavigation() {
      this.$refs.appNavigation.toggleDrawer();
    },
  },
  watch: {
    $route(value) {
      this.currentRoute = value.name;
    },
  },
  mounted() {
    if (isAuthenticated()) {
      this.$store.dispatch('portalUser/getPermissions');
    }
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content--wrap {
  padding-bottom: 100px;
}
</style>
