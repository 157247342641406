<template>
  <div class="dashboard">
    <v-card-title>
      <v-alert
        :value="!!error"
        type="error"
        dismissible
      > There was an error. Please try again or reach out to Headway’s underwriting team
      </v-alert>
      <page-title :title="title"></page-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="newLoanApplicationPermission"
        color="info"
        @click.native="loader = 'loading4'"
        @click.exact="onButtonClick"
        class="new-loan-application"
      >
        New Application
        <template v-slot:loader>
          <span>
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-title>
    <app-data-table
      :headers="headers"
      :items="accounts"
      @rowClick="onRowClick"
    ></app-data-table>
  </div>
</template>

<script>
import AppDataTable from '@/components/AppDataTable.vue';
import PageTitle from '@/components/PageTitle.vue';
import serializeAccount from '@/utils/serialize-account';
import { mapState } from 'vuex';

export default {
  name:       'Dashboard',
  components: {
    AppDataTable,
    PageTitle,
  },
  props: {
    query: {
      type:    String,
      default: '',
    },
  },
  data() {
    return {
      title:   'Account Search',
      headers: [
        {
          text:  'Business Name',
          value: 'businessName',
        },
        {
          text:  'Name',
          value: 'applicantName',
        },
        {
          text:  'Email',
          value: 'email',
        },
        {
          text:  'Phone Number',
          value: 'phoneNumber', // modified in @/utils/serialize-account
        },
      ],
      newLoanApplicationPermissions: '',
    };
  },
  computed: {
    ...mapState('portalUser', ['permissions']),
    ...mapState('account', ['records', 'error']),
    dashboard() {
      return this.permissions.dashboard;
    },
    newLoanApplicationOCRPermission() {
      return this.permissions.appOcr;
    },
    newApplication() {
      return (this.dashboard[0] || {}).target_view === 'new_application';
    },
    accounts() {
      return this.records.map(serializeAccount);
    },
    newLoanApplicationPermission() {
      if (!this.dashboard || !this.dashboard.length) { return false; }
      return this.newApplication;
    },
  },
  methods: {
    onRowClick(data) {
      this.$router.push({
        path: `/accounts/${data.row.id}`,
      });
    },
    onButtonClick() {
      if (this.newLoanApplicationOCRPermission) {
        this.$router.push({
          path: '/new-loan-ocr-application',
          name: 'LoanOCRApplicationForm',
        });
        return;
      }

      this.$router.push({
        path: '/new-loan-application',
        name: 'LoanApplicationForm',
      });
    },

  },
  mounted() {
    this.$store.dispatch('portalUser/getPermissions');
    this.$store.dispatch('account/searchAccounts', this.query);
  },

};
</script>

<style scoped>
.new-loan-application {
  margin-right: 80px;
}
</style>
