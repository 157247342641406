<template>
  <div class="draw-requests">
    <v-card>
      <app-card-title title="Draw Requests"></app-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row >
          <v-col>
            <v-col>
              <p v-for="(request, i) in drawRequests" :key="i">
              ${{currency_format(request.amount)}} funded on {{date_format(request.funding_date)}}
              </p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';
import formatCurrency from '@/utils/format-currency';
import formatDate from '@/utils/format-date';

export default {
  name:       'DrawRequests',
  components: {
    AppCardTitle,
  },
  props: {
    drawRequests: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  methods: {
    currency_format(number) {
      return formatCurrency(number);
    },
    date_format(date) {
      return formatDate(date);
    },
  },
};
</script>
