<template>
  <div class="document-upload">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-alert
            :value="errorFile"
            type="error"
            transition="scale-transition"
          >
            The maximum File Size is 30MB - please use a smaller file
          </v-alert>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model='fileName'
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn
              :label= title
              @click='pickFile'
              :color=  buttonColor
            >
            {{ buttonText }}
          </v-btn>
          <input
              type="file"
              style="display:none"
              ref="file"
              accept=".jpeg, .png, .tiff, .pdf, .txt"
              @change="onFilePicked"
              >
        </v-col>
        <v-col cols="12" sm="3" v-if="fileName">
          <v-select
            v-model="selected"
            :id="id"
            :items="documentTypes"
            item-text="docText"
            item-value="docValue"
            :rules="[v => !!v || 'Please Indicate Document Type']"
            label="Document Type"
            @input="onTypePicked"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  Name: 'DocumentUpload',
  data: () => ({
    file:      '',
    selected:  '',
    errorFile: false,
  }),
  props: {
    buttonText: {
      type:     String,
      default:  'Choose File',
      required: false,
    },
    buttonColor: {
      type:     String,
      default:  'accent',
      required: false,
    },
    title: {
      type:     String,
      default:  'Choose File',
      required: false,
    },
    id: {
      type:     String,
      required: true,
    },
    documentTypes: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
  },
  computed: {
    fileName() {
      return this.file.name;
    },
  },
  methods: {
    pickFile() {
      this.$refs.file.click();
    },
    onFilePicked(e) {
      const { files } = e.target;
      const fileMax = 30000000;
      if (files[0] !== undefined && (files[0].size < fileMax)) {
        [this.file] = files;
        this.$store.dispatch('documents/addFile', this.file);
        this.errorFile = false;
      } else {
        this.fileName = '';
        this.file = '';
        this.errorFile = true;
      }
    },
    onTypePicked(e) {
      this.$store.dispatch('documents/addType', { id: this.id, type: e });
    },
  },
};
</script>
