/* eslint-disable no-param-reassign */
import axios from 'axios';
import router from '@/router';
import { findAccessToken, checkForLatestTokens } from '@/auth/utils/tokens';

const baseUrl = process.env.VUE_APP_API_BASE_URL;
const namespace = process.env.VUE_APP_API_NAMESPACE;

const axiosInstance = axios.create({
  baseURL: `${baseUrl}/${namespace}/`,
});

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

function applyAuthHeaders(config) {
  const accessToken = findAccessToken();
  if (accessToken) {
    config.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return config;
}

axiosInstance.interceptors.request.use(async (config) => {
  try {
    await checkForLatestTokens();
    return applyAuthHeaders(config);
  } catch (err) {
    router.push('/login').catch((e) => { if (e.name !== 'NavigationDuplicated') throw e; });
    return err;
  }
}, () => {
  router.push('/login').catch((e) => { if (e.name !== 'NavigationDuplicated') throw e; });
});

export default axiosInstance;
