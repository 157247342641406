import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import {
  findAccessToken, findRefreshToken, isTokenExpired, refreshAccessToken,
} from '@/auth/utils/tokens';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = findAccessToken();
  const refreshToken = findRefreshToken();
  const authenticated = !isTokenExpired(accessToken);
  const unauthenticatedPages = ['/login', '/privacy-policy'];

  if (!authenticated && refreshToken) {
    try {
      await refreshAccessToken(refreshToken);
      return next();
    } catch (error) {
      return next('/login');
    }
  }

  if (authenticated && to.path === '/login') {
    return next('/');
  }
  if (!authenticated && !unauthenticatedPages.includes(to.path)) {
    return next('/login');
  }
  // clear the tokens from the redirect url, if present
  if (to.query.access_token) {
    return next({ path: to.path });
  }

  return next();
});

export default router;
