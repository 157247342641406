<template>
  <div class="users">
    <app-data-table
      title="Users"
      :headers="headers"
      :items="records"
    ></app-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppDataTable from '@/components/AppDataTable.vue';

export default {
  name:       'Users',
  components: {
    AppDataTable,
  },
  data() {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Username', value: 'username' },
        { text: 'Role', value: 'role' },
        { text: 'Source', value: 'source' },
      ],
    };
  },
  computed: {
    ...mapState('portalUser', ['records']),
  },
};
</script>
