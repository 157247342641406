import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import * as fromLoanOCRApplications from '@/api/loanOCRApplications';

const initialState = () => ({
  errors:    null,
  loading:   false,
  files:     [],
  types:     [],
  documents: [],
  prospect:  0,
});

function uploadDocuments(state) {
  return new Promise((resolve, reject) => {
    const promises = state.files.map((file) => {
      const formData2 = new FormData();
      formData2.append('file', file);
      formData2.append('document_type', file.docType);

      return fromLoanOCRApplications.uploadOCRFile(state.prospect, formData2);
    });
    Promise.all(promises).then((responses) => {
      datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.UploadDocuments.Success');
      resolve(responses);
    }).catch((err) => {
      sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
      datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.UploadDocuments.Error', { error: `${err}`, stack: err.stack });
      Raven.captureException(`${err}:  Found when clicking Submit Application button`, {
        extra: {
          error: {
            code:    err.code,
            message: err.message,
            status:  err.status,
          },
        },
      });
      reject(err);
    });
  });
}

export default {
  namespaced: true,
  state:      initialState(),
  getters:    {
    filesByType: state => type => state.files.filter(file => file.docType === type),
  },
  mutations: {
    addFile(state, payload) {
      payload.id = Date.now();
      state.files.push(payload);
    },
    deleteFile(state, payload) {
      state.files = state.files.filter(file => file.id !== payload.id);
    },
    flush(state) {
      Object.assign(state, initialState());
    },
    storeProspect(state, prospectId) {
      state.prospect = prospectId;
    },
  },
  actions: {
    addFile({ commit }, file) {
      commit('addFile', file);
    },
    deleteFile({ commit }, file) {
      commit('deleteFile', file);
    },
    flush({ commit }) {
      commit('flush');
    },
    sendApplication({ state, commit }, model) {
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append('business_name', model.business_name);
        formData.append('business_industry', model.business_industry);
        formData.append('loan_purpose', model.loan_purpose);
        formData.append('time_in_business', model.time_in_business);
        formData.append('main_phone_number', model.main_phone_number);
        formData.append('email_address', model.email_address);

        if (state.prospect === 0) {
          await fromLoanOCRApplications.createLoanProspect(formData)
            .then((response) => {
              datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.SendApplication.Success');
              commit('storeProspect', response.data.id);
            }).catch((err) => {
              sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
              datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.SendApplication.Error', { error: `${err}`, stack: err.stack });
              Raven.captureException(`${err}:  Found when clicking Submit Application button`, {
                extra: {
                  error: {
                    code:    err.code,
                    message: err.message,
                    status:  err.status,
                  },
                },
              });
              reject(err);
            });
        }

        uploadDocuments(state).then((response) => {
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.UploadDocuments.Success');
          resolve(response);
        }).catch((err) => {
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanOCRApplication.UploadDocuments.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException(`${err}:  Found when clicking Submit Application button`, {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
          reject(err);
        });
      });
    },
  },
};
