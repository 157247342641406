<template>
  <div class="search-input">
    <form @submit.prevent="onsubmit">
      <v-text-field
        placeholder="Search"
        append-icon="mdi-magnify"
        v-model="search"
        hint="Business name or last name"
        id="searchBar"
      ></v-text-field>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      search: '',
    };
  },
  methods: {
    onsubmit() {
      this.$store.dispatch('account/searchAccounts', this.search);
      this.$router.push('/').catch((e) => { if (e.name !== 'NavigationDuplicated') throw e; });
    },
  },
};
</script>
