import * as fromAccounts from '@/api/accounts';

/**
 *
 * maybe refactor files and types to be an array of objects
 * [{ file: File(), type: 'type' }]
 */
const initialState = () => ({
  files:     [],
  types:     [],
  documents: [],
});

export default {
  namespaced: true,
  state:      initialState(),
  mutations:  {
    addFile(state, payload) {
      state.files.push(payload);
    },
    addType(state, payload) {
      const docIndex = state.types.map(t => t.id).indexOf(payload.id);
      if (docIndex > -1) {
        state.types[docIndex].type = payload.type;
      } else {
        state.types.push(payload);
      }
    },
    pushDocuments(state, docs) {
      state.documents = docs;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    addFile({ commit }, files) {
      commit('addFile', files);
    },
    addType({ commit }, type) {
      commit('addType', type);
    },
    flush({ commit }) {
      commit('flush');
    },
    upload({ state }, id) {
      const promises = state.files.map((file, i) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', state.types[i].type);

        return fromAccounts.uploadDocument(id, formData);
      });

      return Promise.all(promises);
    },
    review(_, id) {
      return new Promise((resolve, reject) => {
        fromAccounts.review(id).then((response) => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    documents({ commit }, id) {
      fromAccounts.documents(id).then((response) => {
        const documents = response.data.map(doc => ({
          attachmentName: doc.attachment_name,
          attachmentType: doc.attachment_type,
          receivedTime:   doc.received_time,
        }));

        commit('pushDocuments', documents);
      }).catch(() => {
        commit('error', true);
      });
    },
  },
};
