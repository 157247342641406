<template>
  <div class="loan-application-form">
    <v-progress-linear
        :indeterminate="true"
        :hidden="!loading"
    ></v-progress-linear>
    <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            :hidden="loading"
    >
      <v-container>
        <v-row>
          <v-alert
            :value="errorsInSubmission"
            type="error"
            transition="scale-transition"
            dismissible
          >
            <strong>There was an error. Please try again
              or reach out to Headway’s underwriting team
            </strong>
            <br/>
            <strong v-if="unsupportedCaState">
              Error: California is an unsupported state for this partner.
            </strong>
          </v-alert>
          <v-col cols="12">
            <h2>
              Personal Information
            </h2>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.first_name"
              :rules="firstNameRules"
              label="First Name"
              id="first_name"
              :error="errorsExtraction('first_name')"
              :error-messages="errorMessages('first_name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.last_name"
              :rules="lastNameRules"
              label="Last Name"
              id="last_name"
              :error="errorsExtraction('last_name')"
              :error-messages="errorMessages('last_name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.date_of_birth"
              :rules="[v => !!v || 'Date of Birth is Required']"
              label="Date of Birth"
              id="date_of_birth"
              v-mask="'####-##-##'"
              hint="yyyy-mm-dd"
              :error="errorsExtraction('date_of_birth')"
              :error-messages="errorMessages('date_of_birth')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.email_address"
              :rules="emailAddressRules"
              label="Email Address"
              id="email_address"
              hint="This will be Applicant's User Name"
              :error="errorsExtraction('email_address')"
              :error-messages="errorMessages('email_address')"
              persistent-hint
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.home_address"
              :rules="homeAddressRules"
              label="Home Address"
              id="home_address"
              :error="errorsExtraction('home_address')"
              :error-messages="errorMessages('home_address')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.home_city"
              :rules="homeCityRules"
              label="Home City"
              id="home_city"
              :error="errorsExtraction('home_city')"
              :error-messages="errorMessages('home_city')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.home_state"
              :items="State"
              :rules="[v => !!v || 'Home State is required']"
              label="Home State"
              id="home_state"
              :error="errorsExtraction('home_state')"
              :error-messages="errorMessages('home_state')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.home_postal_code"
              :rules="homePostalCodeRules"
              label="Home Postal Code"
              id="home_postal_code"
              :error="errorsExtraction('home_postal_code')"
              :error-messages="errorMessages('home_postal_code')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.main_phone_number"
              :rules="mainPhoneNumberRules"
              type="phone"
              label="Primary Phone Number"
              v-mask="'(###) ###-####'"
              hint="Numbers only, dashes inserted automatically"
              id="main_phone_number"
              :error="errorsExtraction('main_phone_number')"
              :error-messages="errorMessages('main_phone_number')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.applicant_ssn"
              :rules="applicantSSNRules"
              label="Social Security Number"
              v-mask="'###-##-####'"
              hint="Numbers only, dashes inserted automatically"
              id="applicant_ssn"
              :error="errorsExtraction('applicant_ssn')"
              :error-messages="errorMessages('applicant_ssn')"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container>
        <v-row >
          <v-col cols="12">
            <h2>
                Business Information
            </h2>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.business_name"
              :rules="businessNameRules"
              label="Business Name"
              id="business_name"
              :error="errorsExtraction('business_name')"
              :error-messages="errorMessages('business_name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.business_address"
              :rules="businessAddressRules"
              label="Business Address"
              id="business_address"
              :error="errorsExtraction('business_address')"
              :error-messages="errorMessages('business_address')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.business_city"
              :rules="businessCityRules"
              label="Business City"
              id="business_city"
              :error="errorsExtraction('business_city')"
              :error-messages="errorMessages('business_city')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.business_state"
              :items="State"
              :rules="[v => !!v || 'Business State is required']"
              label="Business State"
              id="business_state"
              :error="errorsExtraction('business_state')"
              :error-messages="errorMessages('business_state')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.business_postal_code"
              :rules="businessPostalCodeRules"
              label="Business Postal Code"
              id="business_postal_code"
              :error="errorsExtraction('business_postal_code')"
              :error-messages="errorMessages('business_postal_code')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.business_phone"
              :rules="businessPhoneRules"
              type="phone"
              label="Business Phone"
              id="business_phone"
              v-mask="'(###) ###-####'"
              hint="Numbers only, dashes inserted automatically"
              :error="errorsExtraction('business_phone')"
              :error-messages="errorMessages('business_phone')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.annual_gross_revenue"
              :rules="[v => !!v || 'Business Gross Revenue is required']"
              label="Business Gross Revenue"
              id="annual_gross_revenue"
              placeholder="Numbers only"
              prefix="$"
              hint="Numbers only"
              :error="errorsExtraction('annual_gross_revenue')"
              :error-messages="errorMessages('annual_gross_revenue')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.time_in_business"
              :rules="[v => !!v || 'Time in Business is required']"
              :items="BusinessTime"
              item-text="TimeType"
              item-value="TimeValue"
              label="Time in Business"
              id="time_in_business"
              :error="errorsExtraction('time_in_business')"
              :error-messages="errorMessages('time_in_business')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.business_entity_type"
              :rules="[v => !!v || 'Business Entity Type is required',]"
              :items="Entity"
              item-text="EntityType"
              item-value="EntityValue"
              label="Business Entity Type"
              id="business_entity_type"
              :error="errorsExtraction('business_entity_type')"
              :error-messages="errorMessages('business_entity_type')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- need to sort how business_tax_id only shows up if
            business_entity_type is anthing other than 'sole proprietorship' -->
            <v-text-field
              v-model="model.business_tax_id"
              :rules="businessTaxIDRules"
              label="Business Tax ID"
              id="business_tax_id"
              :error="errorsExtraction('business_tax_id')"
              :error-messages="errorMessages('business_tax_id')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.business_industry"
              :rules="[v => !!v || 'Business Industry is required']"
              :items="Industry"
              item-text="IndustryType"
              item-value="IndustryValue"
              label="Business Industry"
              id="business_industry"
              :error="errorsExtraction('business_industry')"
              :error-messages="errorMessages('business_industry')"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container>
        <v-row >
          <v-col cols="12">
            <h2>
              Line of Credit Request Details
            </h2>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="model.loan_purpose"
              :rules="[v => !!v || 'Loan Purpose is required']"
              :items="Purpose"
              item-text="PurposeType"
              item-value="PurposeValue"
              label="Purpose of Line of Credit"
              id="loan_purpose"
              :error="errorsExtraction('loan_purpose')"
              :error-messages="errorMessages('loan_purpose')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="model.requested_loan_amount"
              :rules="requestedLoanAmountRules"
              prefix="$"
              label="Requested Line of Credit Limit"
              id="requested_loan_amount"
              placeholder="Numbers only"
              :error="errorsExtraction('requested_loan_amount')"
              :error-messages="errorMessages('requested_loan_amount')"
            ></v-text-field>
          </v-col>
          <TermsAndConditions @changeChecked="tAndCchecked = $event"/>
          <v-btn
            @click="submit"
            color="error"
            class="text-right"
            id="submit-button"
            :disabled="!tAndCchecked"
          >
            Submit Application
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import TermsAndConditions from '@/components/TermsAndConditions.vue';
import { mapState } from 'vuex';

export default {
  name:       'LoanApplicationForm',
  components: {
    TermsAndConditions,
  },
  props: {
    prospect_id: {
      type:    String,
      default: null,
    },
  },
  data: () => ({
    tAndCchecked: false,
    model:        {
      first_name:            '',
      last_name:             '',
      date_of_birth:         '',
      email_address:         '',
      home_address:          '',
      home_city:             '',
      home_state:            null,
      home_postal_code:      '',
      main_phone_number:     '',
      applicant_ssn:         '',
      business_name:         '',
      business_address:      '',
      business_city:         '',
      business_state:        null,
      business_postal_code:  '',
      business_phone:        '',
      annual_gross_revenue:  '',
      time_in_business:      '',
      business_entity_type:  '',
      business_tax_id:       '',
      business_industry:     '',
      loan_purpose:          '',
      requested_loan_amount: '',
    },
    valid:          true,
    firstNameRules: [
      v => !!v || 'First Name is required',
    ],
    lastNameRules: [
      v => !!v || 'Last Name is required',
    ],
    dateOfBirthRules: [],

    emailAddressRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],

    homeAddressRules: [
      v => !!v || 'Home Address is required',
      v => (v && v.length >= 5) || 'Last Name must at least 5 characters',
    ],

    homeCityRules: [
      v => !!v || 'Home City is required',
      v => (v && v.length >= 2) || 'Home City must at least 2 characters',
    ],


    State: [
      'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY',
    ],


    homePostalCodeRules: [
      v => !!v || 'Home Postal Code is required',
      v => (v && v.length >= 5) || 'Home Postal Code must at least 5 characters',
      v => (v && v.length <= 11) || 'Home Postal Code must be less than 11 characters',
    ],

    mainPhoneNumberRules: [
      v => !!v || 'Primary Phone Number is required',
      v => (v && v.length === 14) || 'Primary Phone Number must be 10 digits',
    ],

    applicantSSNRules: [
      v => !!v || 'Social Security Number is required',
      v => (v && v.length === 11) || 'Social Security Number must be 9 digits',
    ],

    businessNameRules: [
      v => !!v || 'Business Name is required',
    ],

    businessAddressRules: [
      v => !!v || 'Business Address is required',
      v => (v && v.length >= 5) || 'Business Address must at least 5 characters',
    ],

    businessCityRules: [
      v => !!v || 'Business City is required',
      v => (v && v.length >= 2) || 'Business City must at least 2 characters',
    ],

    businessPostalCodeRules: [
      v => !!v || 'Business Postal Code is required',
      v => (v && v.length >= 5) || 'Business Postal Code must at least 5 characters',
      v => (v && v.length <= 11) || 'Business Postal Code must be less than 11 characters',
    ],

    businessPhoneRules: [
      v => !!v || 'Business Phone Number is required',
      v => (v && v.length === 14) || 'Business Phone Number must be 10 digits',
    ],


    BusinessTime: [
      { TimeType: 'Less than 1 year', TimeValue: 'less_than_1_year' },
      { TimeType: '1 year to 2 years', TimeValue: '1_year_to_2_years' },
      { TimeType: '2 years to 5 years', TimeValue: '2_years_to_5_years' },
      { TimeType: 'More than 5 years', TimeValue: 'more_than_5_years' },
    ],

    Entity: [
      { EntityType: 'Sole Proprietorship', EntityValue: 'sole_proprietorship' },
      { EntityType: 'LLC', EntityValue: 'llc' },
      { EntityType: 'Corporation', EntityValue: 'corporation' },
      { EntityType: 'Partnership', EntityValue: 'partnership' },
    ],


    businessTaxIDRules: [],

    Industry: [
      { IndustryType: 'Accomodation and Food Services', IndustryValue: 'accomodation_and_food_services' },
      { IndustryType: 'Agriculture, Forestry, Fishing and Hunting', IndustryValue: 'agriculture_forestry_fishing_and_hunting' },
      { IndustryType: 'Arts, Entertainment, Recreation', IndustryValue: 'arts_entertainment_recreation' },
      { IndustryType: 'Automative services', IndustryValue: 'automotive_services' },
      { IndustryType: 'Beauty and wellness services', IndustryValue: 'beauty_and_wellness' },
      { IndustryType: 'Building Equipment Contractors', IndustryValue: 'building_equipment_contractors' },
      { IndustryType: 'Cleaning and janitorial services', IndustryValue: 'cleaning_and_janitorial_services' },
      { IndustryType: 'Construction', IndustryValue: 'construction' },
      { IndustryType: 'Educational services', IndustryValue: 'educational_services' },
      { IndustryType: 'Finance and Insurance', IndustryValue: 'finance_and_insurance' },
      { IndustryType: 'Healthcare and Social Assistance', IndustryValue: 'healthcare_and_social_assistance' },
      { IndustryType: 'Information', IndustryValue: 'information' },
      { IndustryType: 'Landscaping, lawncare, and outdoor services', IndustryValue: 'landscaping_lawncare_and_outdoor_services' },
      { IndustryType: 'Management of Companies and Enterprises', IndustryValue: 'management_of_companies_and_enterprises' },
      { IndustryType: 'Manufacturing', IndustryValue: 'manufacturing' },
      { IndustryType: 'Other Services', IndustryValue: 'other_services' },
      { IndustryType: 'Professional, Scientific, Technical Services', IndustryValue: 'professional_scientific_technical_services' },
      { IndustryType: 'Real Estate', IndustryValue: 'real_estate' },
      { IndustryType: 'Retail Trade', IndustryValue: 'retail_trade' },
      { IndustryType: 'Transportation and Warehousing', IndustryValue: 'transportation_and_warehousing' },
      { IndustryType: 'Wholesale Trade', IndustryValue: 'wholesale_trade' },
    ],


    Purpose: [
      { PurposeType: 'Business Expansion', PurposeValue: 'business_expansion' },
      { PurposeType: 'Buy Inventory or Supplies', PurposeValue: 'buy_inventory_supplies' },
      { PurposeType: 'Cash Flow or General Working Capital', PurposeValue: 'cash_flow_general_working_capital' },
      { PurposeType: 'Debt Consolidation', PurposeValue: 'debt_consolidation' },
      { PurposeType: 'Make Payroll', PurposeValue: 'make_payroll' },
      { PurposeType: 'Marketing or Advertising', PurposeValue: 'marketing_advertising' },
      { PurposeType: 'Pay Rent', PurposeValue: 'pay_rent' },
      { PurposeType: 'Purchase Equipment', PurposeValue: 'purchase_equipment' },
      { PurposeType: 'Taxes', PurposeValue: 'taxes' },
      { PurposeType: 'Other', PurposeValue: 'other' },
    ],

    requestedLoanAmountRules: [],
    errorFields:              [],
    errorMsg:                 {},
    loading:                  false,
    errorsInSubmission:       false,
    unsupportedCaState:       false,
  }),
  computed: {
    ...mapState('loanApplication', ['incompleteAppData']),
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) { return; }
      this.loading = true;

      if (this.prospect_id) {
        this.$data.model.prospect_id = this.prospect_id;
      }

      const applicant = JSON.stringify(this.$data.model);

      this.$store.dispatch('loanApplication/applyFor', applicant).then((response) => {
        datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplicationForm.Submit.Success');
        this.handleResponse(response);
      }).catch((error) => {
        this.handleSubmitErrors(error);
      });
    },
    handleResponse(response) {
      this.loading = false;

      if (this.prospect_id != null && response.decision === 'accept' && response.account_id != null) {
        return this.goToAccount(response.account_id.toString());
      }
      if (response.decision === 'accept') {
        return this.goToUploadDocuments(response.account_id.toString());
      }
      if (response.decision === 'reject') {
        return this.goToRejectApplication(response.decline_reasons);
      }
      if (response.decision === 'existing_customer') {
        return this.goToExistingCustomer();
      }
      return null;
    },
    handleSubmitErrors(error) {
      this.loading = false;
      sentry.captureMessage(`${error}`, { level: 'error', extra: { stack: error.stack } });
      datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplicationForm.Submit.Error', { error: `${error}`, stack: error.stack });

      if (error.response) {
        if (error.response.data.error === 'California is not a supported state for this partner.') {
          this.unsupportedCaState = true;
        }

        if (error.response.status !== 412) {
          this.errorsInSubmission = true;
        }

        Raven.captureException('ApplicationSubmission error response', {
          extra: {
            error: {
              code:    error.code,
              message: error.message,
              status:  error.status,
            },
          },
        });
        this.errorFields = Object.keys(error.response.data.errors);

        return;
      }

      if (error.request) {
        this.errorsInSubmission = true;
        Raven.captureException('ApplicationSubmission error request', {
          extra: {
            error: {
              code:    error.code,
              message: error.message,
              status:  error.status,
            },
          },
        });
      }
    },
    handleApplicationFields() {
      this.loading = false;
      Object.keys(this.$data.model).forEach((key) => {
        this.$data.model[key] = this.incompleteAppData[key];
      });
    },
    goToUploadDocuments(responseId) {
      this.$router.push({
        path:   '/upload-documents',
        name:   'UploadDocuments',
        params: {
          loanOfferStatus: 'application_incomplete',
          id:              responseId,
        },
      });
    },
    goToExistingCustomer() {
      this.$router.push({
        path:   '/existing-customer',
        name:   'ExistingCustomer',
        params: {
          businessName: this.$data.model.business_name,
        },
      });
    },
    goToRejectApplication(declineReasons) {
      this.$router.push({
        path:   '/reject',
        name:   'RejectedApplication',
        params: {
          businessName: this.$data.model.business_name,
          declineReasons,
        },
      });
    },
    goToAccount(accountId) {
      this.$router.push({
        path: `/accounts/${accountId}`,
      });
    },
    errorsExtraction(val) {
      return this.errorFields.includes(val);
    },
    errorMessages(val) {
      let returnValue = [];
      if (this.errorFields.includes(val)) {
        returnValue = this.errorMsg[val];
      }
      return returnValue;
    },
  },
  async mounted() {
    if (this.prospect_id != null) {
      this.loading = true;
      await this.$store.dispatch('loanApplication/incompleteAppData', this.prospect_id)
        .catch((err) => {
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplicationForm.Mounted.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException(`${err}:  ApplicationLoad error`, {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
          this.loading = false;

          this.$router.push({
            name: 'LoanApplicationForm',
          });
        });
      datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplicationForm.Mounted.Success');
      this.handleApplicationFields();
    }
  },
};
</script>

<style scoped>
.loan-application-form {
  margin-bottom: 32px;
}
</style>
