<template>
  <div class="loan-offers">
    <v-row  v-if="offers.length">
      <v-col>
        <v-col>
          <span class="font-weight-medium">Monthly Interest Rate:</span>
          {{offers[0].monthlyInterestRate}}%
        </v-col>
      </v-col>
      <v-col>
        <v-col>
          <span class="font-weight-medium">Application Date:</span>
          {{dateFormat(offers[0].applicationDate)}}
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-col>
          <v-divider></v-divider>
        </v-col>
        <v-col>
          <span>
            Available Offers:
          </span>
        </v-col>
        <v-col>
          <p v-for="(offer, i) in offers" :key="i">
          <span>Option {{i + 1}}: </span>
          Credit limit of
          <span>${{currency_format(offer.maximumLoanAmount)}} </span>
          ~
          {{offer.paymentFrequency}} payments of about
          <span>${{currency_format(offer.paymentAmount)}} </span>
          over
          <span>{{offer.loanTermInMonths}} months </span>
          </p>
        </v-col>
        <v-col>
          <v-divider></v-divider>
        </v-col>
        <v-col>
          <v-alert
            :value="!!error"
            type="error"
            >{{error}}</v-alert>
          <v-alert
            v-model="success"
            dismissible
            type="success"
            >
            An email has been sent to customer’s email listed on the account.
            <br>
            This email will include a secured link to the offer page
            where they can select the terms and proceed to the agreement
          </v-alert>
          <v-btn
            @click="submit"
            color="error"
            class="text-right"
            >
            Send Offer Email to Customer
          </v-btn>
          &nbsp;
          <v-btn
            @click="goToDisclosure(offers[0].loanNumber)"
            color="error"
            class="text-right"
            v-if="disclosureEnabled"
            >
            Offer Summary Disclosure
          </v-btn>
          <v-col>
            <p class='font-weight-thin font-italic'>
            Instant email send that will include
            the option for customer to reset their password.
            <br>
            Once logged in, customer will choose their draw amount,
            repayment term and sign the contract.
            </p>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formatCurrency from '@/utils/format-currency';
import { mapState } from 'vuex';
import formatDate from '@/utils/format-date';

export default {
  name:  'LoanOffers',
  props: {
    id: {
      type:     Number,
      required: true,
    },
    disclosureEnabled: {
      type:     Boolean,
      required: true,
    },
    offers: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  computed: {
    ...mapState('email', ['error', 'success']),
  },
  methods: {
    currency_format(number) {
      return formatCurrency(number);
    },
    dateFormat(date) {
      return formatDate(date);
    },
    submit() {
      this.$store.dispatch('email/send', this.id);
    },
    goToDisclosure(loanNumber) {
      const route = this.$router.resolve({
        path: `/disclosures/${loanNumber}`,
      });
      window.open(route.href);
    },
  },
  mounted() {
    this.$store.dispatch('email/flush');
    this.$store.dispatch('disclosure/flush');
  },
};
</script>

<style scoped>
span {
  font-weight: bold;
}
</style>
