import * as fromAccounts from '@/api/accounts';

const initialState = () => ({
  records:   [],
  recordMap: {},
  account:   {},
  error:     false,
  documents: [],
});

export default {
  namespaced: true,
  state:      initialState(),
  getters:    {},
  mutations:  {
    pushMany(state, payload) {
      state.records = payload;

      payload.forEach((record) => {
        const business = record;
        state.recordMap[record.id] = business;
      });
    },
    addAccount(state, payload) {
      state.account = payload;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
    error(state, payload) {
      state.error = payload;
    },
  },
  actions: {
    flush({ commit }) {
      commit('flush');
    },
    searchAccounts({ commit }, q) {
      if (q.length === 0) { return; }

      fromAccounts.searchAccounts(q)
        .then((response) => {
          const accounts = response.data.map(business => ({
            id:              business.account_id,
            businessName:    business.business_name,
            applicantName:   business.applicant_name,
            email:           business.email,
            mainPhoneNumber: business.main_phone_number,
          }));
          commit('pushMany', accounts);
          commit('error', false);
        }).catch(() => {
          commit('error', true);
        });
    },
    fetchAccount({ commit }, id) {
      fromAccounts.fetchAccount(id)
        .then((response) => {
          let loanOffers = response.data.loan_offers;

          if (loanOffers && loanOffers.length) {
            loanOffers = loanOffers.map(offer => ({
              loanNumber:          offer.loan_number,
              region:              offer.loan_number.substr(4, 2),
              maximumLoanAmount:   offer.maximum_loan_amount,
              applicationDate:     offer.application_date,
              loanTermInMonths:    offer.loan_term_in_months,
              monthlyInterestRate: offer.monthly_interest_rate,
              numInstallments:     offer.num_installments,
              paymentAmount:       offer.payment_amount,
              paymentFrequency:    offer.payment_frequency,
            }));
          }

          let loanApplication = response.data.loan_application;

          if (loanApplication && loanApplication.length) {
            loanApplication = loanApplication.map(loaned => ({
              applicationDate: loaned.application_date,
              creditLimit:     loaned.credit_limit,
              loanTerm:        loaned.loan_term,
              issueDate:       loaned.issue_date,
              interestRate:    loaned.monthly_interest_rate,
              repayment:       loaned.repayment,
              status:          loaned.status,
            }));
          }
          const account = {
            id:                response.data.account_id,
            businessName:      response.data.business_name,
            applicantName:     response.data.applicant_name,
            email:             response.data.email,
            mainPhoneNumber:   response.data.main_phone_number,
            offersReady:       response.data.offers_ready,
            loanOfferStatus:   response.data.loan_offer_status,
            activityType:      response.data.activity_type,
            activityDate:      response.data.activity_date,
            additionalInfo:    response.data.additional_info,
            case_type:         response.data.case_type,
            drawRequests:      response.data.draw_requests,
            declineReasons:    response.data.decline_reasons,
            disclosureEnabled: response.data.disclosure_enabled,
            loanOffers,
            loanApplication,
          };

          commit('addAccount', account);
          commit('error', false);
        }).catch((err) => {
          commit('error', err);
        });
    },
  },
};
