<template>
  <div class="page-title">
    <h1 class="text-h4">{{title}}</h1>
  </div>
</template>

<script>
export default {
  name:  'PageTitle',
  props: {
    title: {
      type:     String,
      default:  '',
      required: true,
    },
  },
};
</script>
