<template>
  <div class="decline-reasons">
    <v-col v-if="declineReasons.length">
      <span> Decline Reasons </span>
      <ul>
        <li v-for="declineReason in declineReasons" :key="declineReason.index">
          {{ declineReason }}
        </li>
      </ul>
    </v-col>
  </div>
</template>

<script>
export default {
  name:  'DeclineReasons',
  props: {
    declineReasons: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
};
</script>
