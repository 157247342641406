<template>
  <div class="account" v-if="account">
    <v-container v-if="error">
      <v-col>
        <v-alert
          :value="!!error"
          type="error"
          dismissible
          >There was an error. Please try again or reach out to Headway’s underwriting team
        </v-alert>
      </v-col>
    </v-container>
    <v-container class="flex" v-if="account.businessName">
      <v-card-title>
        <page-title :title="account.businessName"></page-title>
      </v-card-title>
      <v-row >
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="8">
          <v-row >
            <v-col cols="12">
              <business-details :account="serializedAccount"></business-details>
            </v-col>
            <v-col cols="12">
              <loan-offer-details
              :account="account"
              @fetchAccount="fetchAccount(id)"
            ></loan-offer-details>
            </v-col>
            <v-col cols="12">
              <documents-list
                :id="id"
                :loanOfferStatus="account.loanOfferStatus"
              ></documents-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row >
            <v-col>
              <recent-activity
                :type="account.activityType"
                :date="account.activityDate"
                :info="account.additionalInfo"
              ></recent-activity>
            </v-col>
            <v-col v-if="account.drawRequests && account.drawRequests.length">
              <draw-requests :drawRequests="account.drawRequests"></draw-requests>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import BusinessDetails from '@/components/BusinessDetails.vue';
import LoanOfferDetails from '@/components/LoanOfferDetails.vue';
import DocumentsList from '@/components/DocumentsList.vue';
import RecentActivity from '@/components/RecentActivity.vue';
import DrawRequests from '@/components/DrawRequests.vue';
import serializeAccount from '@/utils/serialize-account';
import { mapState } from 'vuex';

export default {
  name:       'AccountDetails',
  components: {
    PageTitle,
    BusinessDetails,
    LoanOfferDetails,
    DocumentsList,
    RecentActivity,
    DrawRequests,
  },
  props: {
    id: {
      type:     String,
      required: true,
    },
  },
  methods: {
    fetchAccount(id) {
      this.$store.dispatch('account/fetchAccount', id);
    },
  },
  computed: {
    ...mapState('account', ['error', 'account', 'loanOfferStatus']),
    serializedAccount() {
      return serializeAccount(this.account);
    },
  },
  mounted() {
    this.$store.dispatch('account/flush');
    this.$store.dispatch('account/fetchAccount', this.id);
  },
};
</script>
