import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary:   '#343A5E',
        secondary: '#F2F2F2',
        accent:    '#008670',
        error:     '#DC292E',
      },
    },
  },
});
