<template>
  <div class="app-data-table">
    <v-card-title>
      <page-title :title="title"></page-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filter"
        single-line
        hide-details
        @input="onSearchInput"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="paginationData"
      @click:row="onRowClick"
    >
    </v-data-table>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name:       'AppDataTable',
  components: {
    PageTitle,
  },
  props: {
    title: {
      type:    String,
      default: '',
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      search:         '',
      paginationData: this.pagination,
    };
  },
  methods: {
    onSearchInput() {
      this.$emit('input', {
        value: this.search,
      });
    },
    onRowClick(rowData) {
      this.$emit('rowClick', {
        row: rowData,
      });
    },
  },
};
</script>
