<template>
  <v-app id="inspire">
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card class="mt-5">
          <app-card-title :title=title></app-card-title>
          <v-card-text>
            <div>
              <h2>
                <span>Headway’s underwriting team will
                  review and reach out to you with a decision.
                </span>
              </h2>
              <br>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';

export default {
  name:       'ThankYou',
  components: {
    AppCardTitle,
  },
  data() {
    return {
      title: 'Application Completed',
    };
  },
};
</script>
