<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-alert
          :value="errorFile"
          type="error"
          transition="scale-transition">
          The maximum File Size is 30MB - please use a smaller file
        </v-alert>
      </v-col>
      <v-col cols="9" sm="9">
        <span>
          <v-icon color="error">mdi-file-document</v-icon>
          {{ this.title }}
        </span>
      </v-col>
      <v-col cols="3" sm="3" class="text-right">
        <span class="font-weight-regular">
          ({{this.filesByType(this.documentType).length}}/{{this.maxFiles}})
        </span>
        <v-btn icon @click='pickFile' :disabled="!canPickFile">
          <v-icon color="blue">mdi-plus-box</v-icon>
        </v-btn>
      </v-col>
      <input
        type="file"
        style="display:none"
        ref="file"
        accept=".pdf"
        @change="onFilePicked"/>
    </v-row>
    <div v-for="doc in filesByType(this.documentType)" v-bind:key="doc.file">
      <v-row  class="ma-3">
        <v-col cols="9" sm="9">
          {{doc.name}} ({{displaySizeFormatted(doc.size)}})
        </v-col>
        <v-col cols="3" sm="3" class="text-right">
          <v-icon @click='deleteFile(doc)' color="error" class="delete">mdi-delete</v-icon>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OCRDocumentUpload',
  data() {
    return {
      file:      '',
      errorFile: false,
    };
  },
  props: {
    title: {
      type:     String,
      required: true,
    },
    documentType: {
      type:     String,
      required: true,
    },
    maxFiles: {
      type:     Number,
      default:  1,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      filesByType: 'loanOCRApplication/filesByType',
    }),
    canPickFile() {
      return this.filesByType(this.documentType).length < this.maxFiles;
    },
  },
  methods: {
    displaySizeFormatted(size) {
      const decimals = 2;
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return `${parseFloat((size / (k ** i)).toFixed(dm))} ${sizes[i]}`;
    },
    deleteFile(file) {
      this.$store.dispatch('loanOCRApplication/deleteFile', file);
    },
    pickFile() {
      this.$refs.file.click();
    },
    onFilePicked(e) {
      const { files } = e.target;
      const fileMax = 30000000;

      if (files[0] !== undefined && (files[0].size < fileMax)) {
        [this.file] = files;
        this.file.docType = this.documentType;
        this.$store.dispatch('loanOCRApplication/addFile', this.file);
        this.errorFile = false;
      } else {
        this.file = '';
        this.errorFile = true;
      }
    },
  },
};
</script>
