import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import * as sentry from '@sentry/vue';

const dsn = process.env.VUE_APP_SENTRY_DSN || '';

if (dsn) {
  Raven
    .config(dsn, { environment: process.env.VUE_APP_ENV })
    .addPlugin(RavenVue, Vue)
    .install();
}

export default function useSentry() {
  if (dsn) {
    sentry.init({
      Vue,
      dsn,
      logErrors:   true,
      environment: process.env.VUE_APP_ENV,
    });
  }
}
