<template>
  <div class="view-offer-button">
    <v-alert
      :value="!!errorMessage"
      type="error"
      >{{errorMessage}}
    </v-alert>
    <v-btn
      @click="submit"
      color="error"
      class="text-right"
      >
      View Offer
    </v-btn>
  </div>
</template>

<script>
export default {
  name:  'ViewOfferButton',
  props: {
    id: {
      type:     Number,
      required: true,
    },
  },
  data() {
    return {
      error: '',
    };
  },
  computed: {
    errorMessage() {
      if (this.error === 'bad_request' || this.error === 'internal_server_error') {
        return 'There was an error. Please try again or reach out to Headway’s underwriting team';
      }
      if (this.error === 'unprocessable_entity') {
        return 'Offers already available, please refresh the page';
      }
      if (this.error) {
        return `There was an error: ${this.error}`;
      }
      return '';
    },
  },
  methods: {
    submit() {
      this.$store.dispatch('loanOffer/viewOffers', this.id).then(() => {
        this.$store.dispatch('account/fetchAccount', this.id);
      });
    },
  },
};
</script>
