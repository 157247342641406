<template>
  <v-app id="inspire">
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card class="mt-5">
          <v-container fluid class="grey lighten-2 fill-height">
            <v-row class="fill-height">
              <v-col class="align-end flexbox" cols="12">
                <page-sub-title :title=title></page-sub-title>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text>
            <div>
              At this time, we are unable to
              offer a line of credit to {{businessName}}
              <br>
              <div class="decline-content">
                <decline-reasons
                  v-if="declineReasons.length"
                  :declineReasons="declineReasons"
                ></decline-reasons>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import PageSubTitle from '@/components/PageSubTitle.vue';
import DeclineReasons from '@/components/DeclineReasons.vue';

export default {
  name:       'RejectedApplication',
  components: {
    PageSubTitle,
    DeclineReasons,
  },
  props: {
    businessName:   String,
    declineReasons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      title: 'Instant Decision',
    };
  },
};
</script>

<style scoped>
.decline-content {
  padding-top: 2.0em;
}
</style>
