<template>
  <div class="loan-application">
    <v-alert
      :value="!!error"
      type="error"
      dismissible
      >There was an error. Please try again or reach out to Headway’s underwriting team
    </v-alert>
    <v-progress-linear
      :indeterminate="true"
      :hidden="!loading"
    ></v-progress-linear>
    <app-data-table
      :title="title"
      :headers="headers"
      :items="itemsWithFormattedDate"
      :pagination="pagination"
      :hidden="loading"
      @rowClick="onRowClick"
    ></app-data-table>
  </div>
</template>

<script>
import { snake, capital } from 'case';
import AppDataTable from '@/components/AppDataTable.vue';
import formatDate from '@/utils/format-date-yyyymmdd';
import { mapState } from 'vuex';

export default {
  name:       'LoanApplication',
  components: {
    AppDataTable,
  },
  props: {
    status: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        {
          text:  'Business Name',
          value: 'businessName',
        },
      ],
      pagination: {
        sortBy:   ['createdAt'],
        sortDesc: [true],
      },
    };
  },
  computed: {
    ...mapState('loanApplication', ['loading']),
    items() {
      return this.$store.state.loanApplication.loanApplications;
    },
    title() {
      return capital(this.status.replace('mfo-', ''));
    },
    error() {
      return this.$store.state.loanApplication.errors;
    },
    itemsWithFormattedDate() {
      return this.items.map((item) => {
        const date = this.dateFormat(item.createdAt);
        const formattedItem = item;
        formattedItem.createdAt = date;
        return item;
      });
    },
  },
  filters: {
    itemsWithFormattedDate() {
      return this.items.map((item) => {
        const date = this.dateFormat(item.createdAt);
        const formattedItem = item;
        formattedItem.createdAt = date;
        return item;
      });
    },
  },
  methods: {
    snakeCaseStatus(string) {
      return snake(string.replace(/-/g, ' '));
    },
    onRowClick(data) {
      if (data.row.account_id != null) {
        this.$router.push({
          path: `/accounts/${data.row.account_id}`,
        });
      } else if (data.row.prospect_id != null && this.status !== 'mfo-in-underwriting') {
        this.$router.push({
          path: `/new-loan-application/${data.row.prospect_id}`,
        });
      }
    },
    dateFormat(date) {
      return formatDate(date);
    },
  },
  mounted() {
    const status = this.snakeCaseStatus(this.status);
    this.$store.dispatch('loanApplication/loanApplications', status);
  },
  beforeRouteUpdate(to, from, next) {
    const status = this.snakeCaseStatus(to.params.status);
    this.$store.dispatch('loanApplication/loanApplications', status);
    next();
  },
};
</script>
