<template>
  <div>
    <v-col style="max-height: 180px" class="overflow-y-auto">
      <p>
        By checking the box below labeled “I Agree”, you represent
        and warrant to Headway Capital, LLC (“Headway Capital”) that:
        <br>
        (1) you have obtained all necessary consents from the
        applicant Merchant (“Merchant”) and all principals of the Merchant
        to submit this application on behalf of the Merchant;
        <br>
        (2) you have obtained all necessary consents from the Merchant
        and all its principals to allow Headway Capital to obtain
        and use credit information and reports from third parties
        necessary to make a decision on the application, including
        consumer credit reports and data that may be available
        regarding the principals, credit reporting data concerning
        the Merchant, and information from other third parties related
        to the Merchant or its principals; and
        <br>
        (3) you have obtained consent from the Merchant to allow
        Headway Capital to send all information, notices, and disclosures,
        in connection with this application, by way of electronic
        communication from Headway Capital.
      </p>
    </v-col>
    <v-col>
      <input type="checkbox" id="checkbox" v-model="tAndCchecked" @change="changeChecked">
      <label for="checkbox"> I Agree </label>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  data: () => ({
    tAndCchecked: false,
  }),
  methods: {
    changeChecked() {
      this.$emit('changeChecked', this.tAndCchecked);
    },
  },
};
</script>
