<template>
  <div class="app-card-text">
    <v-col>
      <p>{{text}}</p>
    </v-col>
  </div>
</template>

<script>
export default {
  name:  'AppCardText',
  props: {
    text: {
      type:     String,
      default:  '',
      required: true,
    },
  },
};
</script>
