import Raven from 'raven-js';
import * as sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';
import * as fromLoanApplications from '@/api/loanApplications';

const initialState = () => ({
  loanApplications:  [],
  errors:            null,
  loading:           false,
  incompleteAppData: null,
});

export default {
  namespaced: true,
  state:      initialState(),
  getters:    {},
  mutations:  {
    addLoanApplications(state, payload) {
      state.loanApplications = payload;
    },
    loading(state) {
      state.loading = !state.loading;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
    pushAppData(state, data) {
      state.incompleteAppData = data;
    },
  },
  actions: {
    loanApplications({ commit }, status) {
      commit('loading');

      fromLoanApplications.loanApplications(status)
        .then((resp) => {
          const loanApplications = resp.data.map(app => ({
            account_id:   app.account_id,
            prospect_id:  app.prospect_id,
            businessName: app.business_name,
            createdAt:    app.created_at,
            creditLimit:  app.credit_limit,
          }));

          datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.LoanApplications.Success');
          commit('addLoanApplications', loanApplications);
          commit('loading');
        }).catch((err) => {
          sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
          datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.LoanApplications.Error', { error: `${err}`, stack: err.stack });
          Raven.captureException(`${err}:  Found in loanApplication module`, {
            extra: {
              error: {
                code:    err.code,
                message: err.message,
                status:  err.status,
              },
            },
          });
          commit('loading');
        });
    },
    applyFor(_, applicant) {
      return new Promise((resolve, reject) => {
        fromLoanApplications.createLoanApplication(applicant)
          .then((response) => {
            datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.ApplyFor.Success');
            resolve(response.data);
          }).catch((err) => {
            sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
            datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.ApplyFor.Error', { error: `${err}`, stack: err.stack });
            Raven.captureException(`${err}:  Found when clicking New Application button`, {
              extra: {
                error: {
                  code:    err.code,
                  message: err.message,
                  status:  err.status,
                },
              },
            });
            reject(err);
          });
      });
    },
    incompleteAppData({ commit }, id) {
      return new Promise((resolve, reject) => {
        fromLoanApplications.fetchIncompleteApp(id)
          .then((response) => {
            const incompleteAppData = response.data;
            commit('pushAppData', incompleteAppData);
            datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.IncomleteAppData.Success');
            resolve(response.data);
          }).catch((err) => {
            sentry.captureMessage(`${err}`, { level: 'error', extra: { stack: err.stack } });
            datadogRum.addAction('VueHeadwayPartnerPortal.LoanApplication.IncomleteAppData.Error', { error: `${err}`, stack: err.stack });
            Raven.captureException(`${err}:  Found in loanApplication module`, {
              extra: {
                error: {
                  code:    err.code,
                  message: err.message,
                  status:  err.status,
                },
              },
            });
            reject(err);
          });
      });
    },
  },
};
