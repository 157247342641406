<template>
  <div class="new-user">
    <page-title title="NewUser Component"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name:       'NewUser',
  components: {
    PageTitle,
  },
};
</script>
