<template>
  <div class="business-details">
    <v-card>
      <app-card-title title="Business Details"></app-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col>
            <v-col>
                <span>Business Name:</span>
                {{account.businessName}}
            </v-col>
            <v-col >
              <span>Phone:</span>
              {{account.phoneNumber}}
            </v-col>
          </v-col>
          <v-col>
            <v-col>
              <span>Owner Name:</span>
              {{account.applicantName}}
            </v-col>
            <v-col>
            <span>Email:</span>
              {{account.email}}
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppCardTitle from '@/components/AppCardTitle.vue';

export default {
  name:       'BusinessDetails',
  components: {
    AppCardTitle,
  },
  props: {
    account: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
  },
};
</script>

<style scoped>
span {
  font-weight: bold;
}
</style>
