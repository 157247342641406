import { datadogRum } from '@datadog/browser-rum';

export default function useDatadogRum() {
  const id = process.env.VUE_APP_DATADOG_APPLICATION_ID || '';
  const token = process.env.VUE_APP_DATADOG_CLIENT_TOKEN || '';

  if (id && token) {
    datadogRum.init({
      applicationId:           id,
      clientToken:             token,
      site:                    'datadoghq.com',
      service:                 'vue_headway_partner_portal',
      version:                 process.env.npm_package_version,
      env:                     process.env.VUE_APP_ENV,
      sessionSampleRate:       100,
      sessionReplaySampleRate: 20,
      /*
      The trackInteractions initialization parameter enables the collection of user clicks in your
      application. Sensitive and private data contained on your pages may be included to identify
      the elements interacted with.
      */
      trackInteractions:       true,
      trackResources:          true,
      trackLongTasks:          true,
      defaultPrivacyLevel:     'mask-user-input',
    });
  }
}
