
export default (string) => {
  const numbers = string.replace(/\D/g, '');
  const char = { 0: '(', 3: ') ', 6: ' - ' };
  let output = '';
  for (let i = 0; i < numbers.length; i += 1) {
    output += (char[i] || '') + numbers[i];
  }
  return output;
};
