import { createNewToken } from '@/api/user';
import { CookieStorage } from '@enova/login-page-api';
import {
  ACCESS_TOKEN_FIELD_NAME,
  REFRESH_TOKEN_FIELD_NAME,
} from '../constants';

function isHttpOnly() {
  if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
    return true;
  }
  return false; // local dev case
}

export function cookieConfig() {
  return {
    domain:       process.env.VUE_APP_COOKIE_DOMAIN,
    httpOnly:     isHttpOnly(),
    accessToken:  ACCESS_TOKEN_FIELD_NAME,
    refreshToken: REFRESH_TOKEN_FIELD_NAME,
  };
}

function decodeJWT(token) {
  try {
    // Select the 'body' portion of the JWT
    const base64Url = token.split('.')[1];
    // JWT is base64Url encoded, so we must decode for use in `atob`
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64));
  } catch (e) {
    return null;
  }
}

export function removeTokens() {
  CookieStorage.expire(cookieConfig());
}

export function storeTokens(accessToken, refreshToken) {
  CookieStorage.saveTokens(cookieConfig(), accessToken, refreshToken);
}

export function findAccessToken() {
  const accessToken = CookieStorage.AccessToken(cookieConfig()).JWT();
  if (!accessToken) return null;
  return accessToken;
}

export function findRefreshToken() {
  const refreshToken = CookieStorage.RefreshToken(cookieConfig()).JWT();
  if (!refreshToken) return null;
  return refreshToken;
}

export function isTokenExpired(token) {
  const tokenBody = decodeJWT(token);
  if (!tokenBody || typeof tokenBody.exp !== 'number') return true;
  const expiration = tokenBody.exp * 1000;
  return expiration - Date.now() < 0;
}

export function isAuthenticated() {
  return Boolean(findAccessToken());
}

let isRefreshing = false;

// Depending on how many API calls are on the page, the tokens may be asked
// to be refreshed for each API call. This function ensures that only 1 external call
// to oauth happens
export function refreshAccessToken(refreshToken, callApi = true, origResolve, origReject) {
  return new Promise(async (resolve, reject) => {
    // Storing the original resolve and reject functions so that they can bubble up
    const resolveFn = origResolve || resolve;
    const rejectFn = origReject || reject;

    if (callApi && !isRefreshing) {
      isRefreshing = true;
      try {
        const newTokens = await createNewToken(refreshToken);

        if (newTokens.status === 401) {
          const error = { code: 401, message: 'Unauthorized' };
          throw error;
        }

        storeTokens(newTokens.data.access_token, newTokens.data.refresh_token);
        isRefreshing = false;
        resolveFn();
      } catch (error) {
        removeTokens();
        isRefreshing = false;
        rejectFn(error);
      }
    }

    // If the tokens are already in the process of refreshing, just call the method again
    // so that the extra calls are resolved when the original oauth call is complete
    if (isRefreshing) {
      setTimeout(async () => {
        await refreshAccessToken(refreshToken, false, resolveFn, rejectFn);
      }, 100);
    }

    // If there's no calls to be made and none are in process, done!
    if (!callApi && !isRefreshing) {
      resolveFn();
    }
  });
}

export async function checkForLatestTokens() {
  const token = findAccessToken();

  if (isTokenExpired(token)) {
    try {
      const refreshToken = findRefreshToken();
      await refreshAccessToken(refreshToken);
      return findAccessToken();
    } catch (error) {
      return error;
    }
  }

  return token;
}
