import axiosInstance from './intercept';

/* eslint-disable import/prefer-default-export */
export function loanApplications(status) {
  return axiosInstance.get('/v1/loan_applications', { params: { view: status } });
}

export function createLoanApplication(applicant) {
  return axiosInstance.post('/v1/loan_applications', JSON.parse(applicant));
}

export function fetchIncompleteApp(id) {
  return axiosInstance.get(`/v1/prospects/${id}`);
}
