import * as fromAccount from '@/api/accounts';

const initialState = () => ({
  success: false,
  error:   '',
});

export default {
  namespaced: true,
  state:      initialState(),
  mutations:  {
    error(state, payload) {
      if (['bad_request', 'internal_service_error'].includes(payload.error)) {
        state.error = 'There was an error. Please try again or reach out to Headway’s underwriting team';
      } else {
        state.error = 'There was an error. Please try again.';
      }
    },
    success(state, payload) {
      state.success = payload;
    },
    flush(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    send({ commit }, id) {
      fromAccount.email(id).then(() => {
        commit('success', true);
      }).catch((err) => {
        commit('error', err.response.data);
      });
    },
    flush({ commit }) {
      commit('flush');
    },
  },
};
